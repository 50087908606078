import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import SearchIcon from '@material-ui/icons/Search';
import CardContent from "@material-ui/core/CardContent";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormHelperText, IconButton, InputAdornment, InputBase, TextField, Tooltip, Typography } from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import { apiGeneral } from "../../utils/urls";
import { networkRequest } from "../../utils/networkRequest";
import { domain } from "../../utils/domain";
import { Link } from "react-router-dom";
import Swal from 'sweetalert2'
import { useLoading } from "../../Components/Layouts/LoadingProvider";
import { getFileExtension } from "../../Extras/helpers";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  card: {
    marginBottom: 16,
    backgroundColor: "#3f51b5",
    color: "white",
  },
  errorHelperText: {
    color: "red",
    fontSize: "1rem",
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: "white",
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const Wrapper = ({ children }) => <div style={{ padding: 16 }}>{children}</div>;

export default function UnVerifiedCandidate() {
  const classes = useStyles();
  const { loading, setLoading } = useLoading();
  const isLoading = (bool) => {
    setLoading(bool);
  }

  const [tmpCandidateList, setTmpCandidateList] = useState([]);
  const [searchKey, setSearchKey] = useState('');
  const [nonFilterCandidateList, setNonFilterCandidateList] = useState([]);
  const [selectedDistrict, setSelectedDistrict] = useState("all");
  const [districts, setDistricts] = useState([]);
  const [statuses, setStatuses] = useState(['ALL', 'PENDING', 'DISTRICT', 'REJECTED']);
  const [role, setRole] = useState('user')
  const [selectedStatus, setSelectedStatus] = useState('ALL');

  const fetchCustomerListData = () => {
    networkRequest(apiGeneral.GetTmpCandidateList, isLoading, handleResponse);
  };

  const handleResponse = (response) => {
    setNonFilterCandidateList(response["data"]);
    setTmpCandidateList(response["data"]);
  };

  useEffect(() => {
    const role = localStorage.getItem('role');
    setRole(role)
    const fetchDistricts = async () => {
      if (role !== 'admin') {
        setDistricts([{ district: "Not Available", id: 0 }])
        return;
      }
      setLoading(true)
      try {
        const response = await axios.get(
          apiGeneral.getDistricts
        );
        const district = [{ district: "All", id: 'all' }]
        district.push(...response.data['data'])
        setDistricts(district);
      } catch (error) {
      } finally {
        setLoading(false)
      }
    };

    fetchDistricts();
  }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      let filteredList = nonFilterCandidateList;
      if (searchKey !== '') {
        filteredList = filteredList.filter(row =>
          row.aadhaar.includes(searchKey)
        );
      }

      if (selectedDistrict !== 'all') {
        filteredList = filteredList.filter(row =>
          row.district_id === selectedDistrict
        );
      }

      if (selectedStatus !== 'ALL') {
        filteredList = filteredList.filter(row =>
          row.verification_status === selectedStatus.toLowerCase()
        );
      }
      setTmpCandidateList(filteredList);
      // setTmpCandidateList(nonFilterCandidateList);
    }, 500); // 500ms delay

    return () => clearTimeout(delayDebounceFn);
  }, [nonFilterCandidateList, searchKey, selectedDistrict, selectedStatus]);

  useEffect(() => { fetchCustomerListData() }, []);

  const handleCandidateVerification = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "Verified The Candidate",
      icon: 'success',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Verified  it!'
    }).then((result) => {
      if (result.isConfirmed) {
        const formDataToSend = new FormData();
        formDataToSend.append("id", id);
        networkRequest(
          apiGeneral.candidateVerification,
          isLoading,
          handleCandidateVerificationResponse,
          "post",
          formDataToSend
        );
      }
    });
  };

  const handleCandidateVerificationResponse = (data) => {
    Swal.fire("success", data["message"]);
    fetchCustomerListData();
  };

  const [showDocPopup, setShowDocPopup] = useState(false);
  const [imagePopUp, setImagePopUp] = useState('')

  const showDocumentPopup = (url) => {
    setImagePopUp(domain + "/" + url)
    setShowDocPopup(true);
  };

  const closeDocumentPopup = () => {
    setShowDocPopup(false);
  };

  const [reject, setReject] = useState(false);
  const [rejectId, setRejectId] = useState(0);
  const handleRejectCandidate = (id) => {
    setRejectId(id);
    setReject(true);
  };

  const handleRejectClose = () => {
    setReject(false);
  };

  const [formData, setFormData] = useState({
    reason: "",
  });
  const [errors, setErrors] = useState({});

  const handleRejectInput = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const validateForm = () => {
    const errorsCopy = {}; // Initialize a new errors object

    // Validate reason
    if (!formData.reason.trim() === "" || formData.reason.length < 10) {
      errorsCopy.reason = "Reason is Required";
    }

    setErrors(errorsCopy);
  };

  useEffect(() => {
    validateForm();
  }, [formData]);

  const handleSubmit = (id) => {
    setReject(false);
    const formDataToSend = new FormData();
    formDataToSend.append("id", id);
    formDataToSend.append("note", formData.reason);
    networkRequest(
      apiGeneral.candidateReject,
      isLoading,
      handleCandidateRejectResponse,
      "post",
      formDataToSend
    );
  };

  const handleCandidateRejectResponse = (data) => {
    Swal.fire("success", data["message"]);
    setReject(false);
    setFormData({ reason: "" });
    fetchCustomerListData();
  };

  return (
    <Wrapper>
      <Card className={classes.card}>
        <CardContent>
          <Grid
            spacing={10}
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item xs={12} sm={8}>
              <Typography variant="h4">
                Unverified Candidates List
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Grid container spacing={1}>
        <Grid container spacing={0}>
          <Grid
            item
            xs={4}
            sm={4}
            style={{
              marginBottom: "10px",
            }}>
            <TextField
              id="outlined-select-DivisionClasses-native"
              select
              name="district_id"
              label="All District"
              onChange={(e) => {
                setSelectedDistrict(e.target.value)
              }}
              SelectProps={{
                native: true,
              }}
              variant="outlined"
              className={classes.dropDownField}
              size="small"
            >
              {districts.map((district) => (
                <option key={district.id} value={district.id}>
                  {district.district}
                </option>
              ))}
            </TextField>
          </Grid>
          <Grid
            item
            xs={4}
            sm={4}
            style={{
              marginBottom: "10px",
            }}>
            <TextField
              id="outlined-select-DivisionClasses-native"
              select
              name="status"
              label="status"
              onChange={(e) => {
                setSelectedStatus(e.target.value)
              }}
              SelectProps={{
                native: true,
              }}
              variant="outlined"
              className={classes.dropDownField}
              size="small"
            >
              {statuses.map((status) => (
                <option key={status} value={status}>
                  {status}
                </option>
              ))}
            </TextField>
          </Grid>
          <Grid
            item
            xs={4}
            sm={4}
            style={{
              marginBottom: "10px",
            }}>
            <TextField
              variant="outlined"
              placeholder="Search… by aadhar"
              size="small"
              onChange={(e) => {
                setSearchKey(e.target.value);
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment>
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Photo</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>DOB</TableCell>
                <TableCell>Gender</TableCell>
                <TableCell>Contact</TableCell>
                <TableCell>Aadhaar</TableCell>
                <TableCell>Mother</TableCell>
                <TableCell>District</TableCell>
                <TableCell>Address</TableCell>
                <TableCell>Verification Status</TableCell>
                <TableCell align="left">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tmpCandidateList.map((row) => (
                <TableRow key={row.id} value={row.id}>
                  <TableCell component="th" scope="row">
                    {row.id}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <img
                      src={`${domain}/${row.photo}`}
                      alt={`${row.name}'s profile`}
                      width={100}
                    />
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.email}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.dob}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.gender}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.contact}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.aadhaar}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.mother}
                  </TableCell>
                  <TableCell align="left">{row.district}</TableCell>
                  <TableCell component="th" scope="row">
                    {row.address}
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    style={{
                      color:
                        row.verification_status === "state"
                          ? "green"
                          : row.verification_status === "pending"
                            ? "orange"
                            : row.verification_status === "rejected"
                              ? "red"
                              : row.verification_status === "district"
                                ? "green"
                                : "inherit",
                    }}
                  >
                    {row.verification_status.toUpperCase()}
                    {row.verification_status === "rejected" ? ` ${row.note}` : ''}
                  </TableCell>
                  <TableCell align="left" className={classes.actionButtons}>
                    <Tooltip title="View Document">
                      <Button
                        variant="contained"
                        onClick={() => {
                          showDocumentPopup(row.document);
                        }}
                        color="primary"
                        style={{
                          marginBottom: "5px",
                          color: "white",
                        }}
                      >
                        {<VisibilityIcon style={{ color: 'white' }} />}
                      </Button>
                    </Tooltip>
                    <Tooltip title="Verify">
                      <Button
                        onClick={() => {
                          handleCandidateVerification(row.id);
                        }}
                        variant="contained"
                        style={{
                          marginBottom: "5px",
                          backgroundColor: "#1181E6",
                          color: "white",
                        }}
                      >
                        {<CheckIcon />}
                      </Button>
                    </Tooltip>
                    <Tooltip title="Reject">
                      <Button
                        onClick={() => handleRejectCandidate(row.id)}
                        variant="contained"
                        color="secondary"
                        style={{
                          marginBottom: "5px"
                        }}
                      >
                        {<CloseIcon />}
                      </Button>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Dialog open={showDocPopup} onClose={closeDocumentPopup}>
        <DialogContent>
          {getFileExtension(imagePopUp) !== 'pdf' ? (
            <img
              src={imagePopUp}
              alt=""
              style={{ maxWidth: "100%", height: "auto" }}
            />
          ) : (
            <object width="600" height="500" data={imagePopUp} type="application/pdf"></object>
          )}
          <DialogActions>
            <Button onClick={closeDocumentPopup}>Close</Button>
          </DialogActions>
        </DialogContent>
      </Dialog>

      <Dialog open={reject} onClose={handleRejectClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Reject Candidate</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please provide a reason for rejecting the candidate.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="reason"
            label="Reason for rejection"
            type="text"
            name="reason"
            value={formData.reason}
            onChange={handleRejectInput}
            fullWidth
          />
          {errors.reason && (
            <FormHelperText className={classes.errorHelperText}>
              {errors.reason}
            </FormHelperText>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleRejectClose} color="primary">
            Cancel
          </Button>
          <Button onClick={() => handleSubmit(rejectId)} variant="contained" color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </Wrapper >
  );
}
