import React, { useState } from "react";
import Wrapper from "../../../Components/Wrapper";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import {
  CardActionArea,
  CardMedia,
  Typography,
} from "@material-ui/core";
import { Link, useNavigate, useParams } from "react-router-dom";
import ArrowBackOutlinedIcon from "@material-ui/icons/ArrowBackOutlined";
import { downloadPDF } from "../../../utils/download-pdf";
const useStyles = makeStyles({
  card: {
    marginBottom: 16,
    backgroundColor: "#3f51b5",
    color: "white",
  },
  actionButtons: {
    display: "flex",
    gap: 8, // Adjust the spacing between buttons
  },
  dropDownField: {
    width: "200px",
    marginRight: "10px",
  },
  textAlignRight: {
    textAlign: "right",
  },
  root: {
    maxWidth: 345,
    background: "linear-gradient(to bottom right, #3f51b5, #9c27b0)",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)", // Adding a background shadow
    borderRadius: 10,
    overflow: "hidden",
  },
  media: {
    height: 200,
    objectFit: "cover",
  },
});

const EventDashboard = () => {
  const classes = useStyles();
  const { id, district_id } = useParams();
  const navigate = useNavigate();
  // const [eventData, setEventData] = useState({});
  const handlePdfDownload = () => {
    try {
      downloadPDF(id, district_id)
      alert('file downloaded')
    } catch (error) {
      alert('failed to download file')
    }
  }
  return (
    <div>
      <Wrapper>
        <Card className={classes.card}>
          <CardContent>
            <Grid
              spacing={10}
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item xs={12} sm={12}>
                <Typography variant="h4">
                  <ArrowBackOutlinedIcon onClick={() => { navigate(-1) }} /> Event Dashboard
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Grid container spacing={2}>
          <Grid item xs={6} sm={6} md={3}>
            <Card className={classes.root}>
              <CardActionArea>
                <Link
                  to={`/event/candidate/entry-form/${id}/Boys/${district_id}`}
                >
                  <CardMedia
                    className={classes.media}
                    image="https://img.freepik.com/free-photo/fencing_654080-2270.jpg?t=st=1716965812~exp=1716969412~hmac=3570ef2dc137c4a8075b8971e1e6d402425495cd1f1804b47aa95eae83290c08&w=1060"
                    title="Fencing"
                  />
                  <CardContent>
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="h2"
                      align="center"
                      style={{ color: "#fff" }}
                    >
                      Male Candidate
                    </Typography>
                  </CardContent>
                </Link>
              </CardActionArea>
            </Card>
          </Grid>
          <Grid item xs={6} sm={6} md={3}>
            <Card className={classes.root}>
              <CardActionArea>
                <Link
                  to={`/event/candidate/entry-form/${id}/Girls/${district_id}`}
                >
                  <CardMedia
                    className={classes.media}
                    // image="https://img.freepik.com/free-photo/teen-girl-fencing-costume-with-sword-hand-isolated-white-studio-background_155003-41820.jpg?t=st=1716965622~exp=1716969222~hmac=da6c26d7660fa4d366a7d1aa2bdfce1c07c0d5e451a77fec6cbf787975285d82&w=996"
                    image="https://img.freepik.com/free-photo/fencing_654080-2252.jpg?t=st=1716965841~exp=1716969441~hmac=d85d1015af29eb25dceeab2a546889b331530345ce15f05aab0e979b3e5250f0&w=1060"
                    title="Fencing"
                  />
                  <CardContent>
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="h2"
                      align="center"
                      style={{ color: "#fff" }}
                    >
                      Female Candidate
                    </Typography>
                  </CardContent>
                </Link>
              </CardActionArea>
            </Card>
          </Grid>
          <Grid item xs={6} sm={6} md={3}>
            <Card className={classes.root}>
              <CardActionArea>
                <Link
                  to={`/event/add/coach-and-authorities/${id}/${district_id}`}
                >
                  <CardMedia
                    className={classes.media}
                    image="https://t4.ftcdn.net/jpg/07/08/02/69/240_F_708026937_eQoIQirg2xFHOizAM8e027zHxah8Rnfp.jpg"
                    title="Fencing"
                  />
                  <CardContent>
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="h2"
                      align="center"
                      style={{ color: "#fff" }}
                    >
                      Coach Details
                    </Typography>
                  </CardContent>
                </Link>
              </CardActionArea>
            </Card>
          </Grid>
          <Grid item xs={6} sm={6} md={3}>
            <Card className={classes.root}>
              <CardActionArea onClick={handlePdfDownload}>
                <CardMedia
                  className={classes.media}
                  image="https://img.freepik.com/premium-vector/illustration-vector-graphic-cartoon-character-company-activity-report_516790-1823.jpg"
                  title="Fencing"
                />
                <CardContent>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="h2"
                    align="center"
                    style={{ color: "#fff" }}
                  >
                    View Report
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        </Grid>
      </Wrapper>
    </div>
  );
};
export default EventDashboard;
