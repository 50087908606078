import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import SearchIcon from '@material-ui/icons/Search';
import CardContent from "@material-ui/core/CardContent";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormHelperText, InputBase, TextField, Typography } from "@material-ui/core";
import { apiGeneral } from "../../utils/urls";
import { networkRequest } from "../../utils/networkRequest";
import { domain } from "../../utils/domain";
import Swal from 'sweetalert2'
import { useLoading } from "../../Components/Layouts/LoadingProvider";
import { getFileExtension } from "../../Extras/helpers";

const useStyles = makeStyles((theme) => ({
  card: {
    marginBottom: 16,
    backgroundColor: "#3f51b5",
    color: "white",
  },
  errorHelperText: {
    color: "red",
    fontSize: "1rem",
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: "white",
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const Wrapper = ({ children }) => <div style={{ padding: 16 }}>{children}</div>;

export default function TmpCandidates() {
  const classes = useStyles();
  const { setLoading } = useLoading();
  const isLoading = (bool) => {
    setLoading(bool);
  }

  const [tmpCandidateList, setTmpCandidateList] = useState([]);
  const [searchKey, setSearchKey] = useState('');
  const [nonFilterCandidateList, setNonFilterCandidateList] = useState([]);

  const fetchCustomerListData = () => {
    networkRequest(apiGeneral.GetUnpaidCandidateList, isLoading, handleResponse);
  };

  const handleResponse = (response) => {
    setNonFilterCandidateList(response["data"]);
    setTmpCandidateList(response["data"]);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchKey !== '') {
        const filteredList = nonFilterCandidateList.filter(row =>
          row.aadhaar.includes(searchKey)
        );
        setTmpCandidateList(filteredList);
      } else {
        setTmpCandidateList(nonFilterCandidateList);
      }
    }, 500); // 500ms delay

    return () => clearTimeout(delayDebounceFn);
  }, [nonFilterCandidateList, searchKey]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { fetchCustomerListData() }, []);

  return (
    <Wrapper>
      <Card className={classes.card}>
        <CardContent>
          <Grid
            spacing={10}
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item xs={12} sm={8}>
              <Typography variant="h4">
                Temporary Candidates List
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <div className={classes.search}>
                <div className={classes.searchIcon}>
                  <SearchIcon />
                </div>
                <InputBase
                  onChange={(e) => {
                    setSearchKey(e.target.value);
                  }}
                  placeholder="Search… by aadhar"
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                  }}
                  inputProps={{ 'aria-label': 'search' }}
                />
              </div>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Grid container spacing={1}>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Photo</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>DOB</TableCell>
                <TableCell>Gender</TableCell>
                <TableCell>Contact</TableCell>
                <TableCell>Aadhaar</TableCell>
                <TableCell>Mother</TableCell>
                <TableCell>District</TableCell>
                <TableCell>Address</TableCell>
                <TableCell>Verification Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tmpCandidateList.map((row) => (
                <TableRow key={row.id} value={row.id}>
                  <TableCell component="th" scope="row">
                    {row.id}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <img
                      src={`${domain}/${row.photo}`}
                      alt={`${row.name}'s profile`}
                      width={100}
                    />
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.email}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.dob}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.gender}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.contact}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.aadhaar}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.mother}
                  </TableCell>
                  <TableCell align="left">{row.district}</TableCell>
                  <TableCell component="th" scope="row">
                    {row.address}
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    style={{
                      color:
                        row.verification_status === "state"
                          ? "green"
                          : row.verification_status === "pending"
                            ? "orange"
                            : row.verification_status === "rejected"
                              ? "red"
                              : row.verification_status === "district"
                                ? "green"
                                : "inherit",
                    }}
                  >
                    {row.verification_status.toUpperCase()}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Wrapper>
  );
}
