import {
  Drawer,
  Hidden,
  List,
  SwipeableDrawer,
  makeStyles,
} from "@material-ui/core";
import React from "react";
import { drawerWidth } from "../../Extras/styleVariables";
import NavCandidate from "../Sidebar/NavCandidate";

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    position: "relative",
    width: drawerWidth,
    maxWidth: drawerWidth,
    height: "100%",
    zIndex: theme.zIndex.drawer + 99,
  },
  modal: {
    [theme.breakpoints.down("sm")]: {
      top: "56px!important",
    },
    [theme.breakpoints.up("sm")]: {
      top: "64px!important",
    },
    zIndex: "1000!important",
  },
  backdrop: {
    [theme.breakpoints.down("sm")]: {
      top: "56px",
    },
    [theme.breakpoints.up("sm")]: {
      top: "64px",
    },
  },
}));
export default function SidebarCandidate({ opened, toggleDrawer }) {
  const classes = useStyles();
  return (
    <>
      <Hidden smDown>
        <Drawer
          variant="persistent"
          classes={{
            paper: classes.drawerPaper,
          }}
          open={opened}
          ModalProps={{
            keepMounted: false,
            className: classes.modal,
            BackdropProps: {
              className: classes.backdrop,
            },
            onBackdropClick: toggleDrawer,
          }}
        >
          <List component="div">
            <NavCandidate />
          </List>
        </Drawer>
      </Hidden>
      <Hidden mdUp>
        <SwipeableDrawer
          variant="temporary"
          classes={{
            paper: classes.drawerPaper,
          }}
          open={opened}
          onClose={toggleDrawer}
          onOpen={toggleDrawer}
          ModalProps={{
            keepMounted: false,
            className: classes.modal,
            BackdropProps: {
              className: classes.backdrop,
            },
            onBackdropClick: toggleDrawer,
          }}
        >
          <List component="div">
            <NavCandidate />
          </List>
        </SwipeableDrawer>
      </Hidden>
    </>
  );
}
