import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import EventNoteIcon from "@material-ui/icons/EventNote";
import {
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  TextField,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Cancel, Save } from "@material-ui/icons";
import axios from "axios";
import { apiGeneral } from "../../utils/urls";
import Swal from "sweetalert2";
import { useLoading } from "../../Components/Layouts/LoadingProvider";
import { networkRequest } from "../../utils/networkRequest";

const useStyles = makeStyles((theme) => ({
  table: {
    // minWidth: 650,
  },
  card: {
    marginBottom: 16,
    display: "flex",
    gap: 8,
  },
  actionButtons: {
    // display: "flex",
    // gap: "1px", // Adjust the spacing between buttons
    // // marginBottom: "5px",
  },
  search: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid black",
    borderRadius: "5px",
  },
  margin: {
    margin: theme.spacing(2),
  },
  textAlignRight: {
    textAlign: "right",
  },
  bgPrimary: {
    width: "100%",
    backgroundColor: "#3f51b5",
    color: "white",
  },
  padding: {
    padding: theme.spacing(2),
  },
  textField: {
    width: "60ch",
  },
  textAlignRight: {
    textAlign: "right",
  },
  textField20ch: {
    width: "20ch",
  },
  errorhelperText: {
    color: "red",
    fontSize: "1rem",
  },
  truncateText: {
    maxWidth: "200px", // Adjust as needed
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));

const Wrapper = ({ children }) => <div style={{ padding: 16 }}>{children}</div>;

export const role = [
  // {
  //   value: "",
  //   label: "",
  // },
  {
    value: "user",
    label: "USER",
  },
  // {
  //   value: "verifier",
  //   label: "VERIFIER",
  // },
];

export default function AddUsers() {
  const location = useLocation();
  const classes = useStyles();
  const [districts, setDistricts] = useState([""]);
  // const [role, setRole] = useState([]);
  const navigate = useNavigate();
  const { loading, setLoading } = useLoading();
  const isLoading = (bool) => {
    setLoading(bool);
  }

  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    district_id: "1",
    name: "",
    contact: "",
    email: "",
    role: "user",
  });

  const handleInputChange = (event) => {
    console.log(event.target.value)
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  // const handleChange = (event) => {
  //   setRole(event.target.value);
  // };

  const validateForm = () => {
    const errorsCopy = {}; // Initialize a new errors object

    // Validate district
    if (!formData.district_id) {
      errorsCopy.district = "District is Required";
    }

    // Validate name
    if (formData.name === "" || formData.name.length < 5) {
      errorsCopy.name = "Name is Required";
    }
    // Validate contact
    if (formData.contact === "" || formData.contact.length < 10 || formData.contact.length > 10) {
      errorsCopy.contact = "Contact is Required";
    }
    // Validate userTypes
    if (formData.role === '') {
      errorsCopy.role = "Role is required";
    }

    // Validate email
    if (!formData.email) {
      errorsCopy.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errorsCopy.email = "Invalid email address";
    }

    setErrors(errorsCopy);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
// error handle
    if(Object.keys(errors).length !== 0){
      return;
    }
    
    const formDataToSend = new FormData();
    formDataToSend.append("district_id", formData.district_id);
    formDataToSend.append("name", formData.name);
    formDataToSend.append("contact", formData.contact);
    formDataToSend.append("email", formData.email);
    formDataToSend.append("role", formData.role);

    if (location.state !== null)
      formDataToSend.append('id', location.state['id'])

    
    networkRequest(apiGeneral.createUser, isLoading, handleResponse, 'post', formDataToSend);
  };

  const handleResponse = (response) => {
    if (response["success"])
      navigate('/users')

    Swal.fire({
      title: "SUCCESS",
      text: JSON.stringify(response["message"]),
    });

  }

  useEffect(
    (e) => {
      validateForm();
    },
    [formData]
  );
  useEffect(() => {
    const fetchDistricts = async () => {
      setLoading(true)
      try {
        const response = await axios.get(
          apiGeneral.getDistricts
        );
        setDistricts(response.data["data"]);
        console.log(response.data["data"]);
      } catch (error) {
        console.error("Error fetching districts:", error);
      } finally {
        setLoading(false)
      }
    };

    fetchDistricts();
  }, []);

  useEffect(() => {
    if (location.state !== null) {
      setFormData(location.state);
      console.log(setFormData)
    }
  }, []);


  return (
    <Wrapper>
      <Card className={classes.card}>
        <Grid sm={12}>
          <Typography
            className={clsx(classes.padding, classes.bgPrimary)}
            variant="h4"
          >
            <EventNoteIcon /> Add Users
          </Typography>
          <Card>
            <FormControl className={clsx(classes.margin, classes.textField)}>
              <InputLabel htmlFor="standard-adornment-password">
                NAME
              </InputLabel>
              <Input
                id="standard-adornment-Roll-number"
                type="text"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
              />
              {errors.name && (
                <FormHelperText className={classes.errorhelperText}>
                  {errors.name}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl className={clsx(classes.margin, classes.textField)}>
              <InputLabel htmlFor="standard-adornment-password">
                EMAIL
              </InputLabel>
              <Input
                id="standard-adornment-Roll-number"
                type="text"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
              />
              {errors.email && (
                <FormHelperText className={classes.errorhelperText}>
                  {errors.email}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl className={clsx(classes.margin, classes.textField)}>
              <InputLabel htmlFor="standard-adornment-password">
                CONTACT
              </InputLabel>
              <Input
                id="standard-adornment-Roll-number"
                type="number"
                name="contact"
                value={formData.contact}
                onChange={handleInputChange}
              />
              {errors.contact && (
                <FormHelperText className={classes.errorhelperText}>
                  {errors.contact}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl className={clsx(classes.margin, classes.textField)}>
              <TextField
                id="outlined-select-DivisionClasses-native"
                select
                name="district_id"
                value={formData.district_id}
                onChange={handleInputChange}
                SelectProps={{
                  native: true,
                }}
                variant="outlined"
                className={classes.dropDownField}
                size="small"
              >
                {districts.map((district) => (
                  <option key={district.id} value={district.id}>
                    {district.district}
                  </option>
                ))}
              </TextField>
              {errors.district && (
                <FormHelperText className={classes.errorhelperText}>
                  {errors.district}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl className={clsx(classes.margin, classes.textField)}>
              <TextField
                id="outlined-select-DivisionClasses-native"
                select
                name="role"
                label="Role"
                value={formData.role}
                onChange={handleInputChange}
                SelectProps={{
                  native: true,
                }}
                variant="outlined"
                className={classes.dropDownField}
                size="small"
              >
                {role.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </TextField>
              {errors.role && (
                <FormHelperText className={classes.errorhelperText}>
                  {errors.role}
                </FormHelperText>
              )}
            </FormControl>

            <Card>
              <Button
                variant="contained"
                color="Primary"
                size="large"
                className={clsx(classes.margin, classes.button)}
                startIcon={<Save />}
                type="submit"
                onClick={handleSubmit}
              >
                Save
              </Button>
              <Link to={"/users"}>
                <Button
                  variant="contained"
                  color="Default"
                  size="large"
                  className={clsx(classes.margin, classes.button)}
                  startIcon={<Cancel />}
                >
                  Cancel
                </Button>
              </Link>
            </Card>
          </Card>
        </Grid>
      </Card>
    </Wrapper>
  );
}
