import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./Screens/login/Login";
import Dashboard from "./Screens/dashboard/Dashboard";
import MainLayout from "./Components/Layouts/MainLayout";
import AddEvents from "./Screens/events/AddEvents";
import Candidates from "./Screens/candidates/Candidates";
import EventDetails from "./Screens/events/EventDetails";
import AddUsers from "./Screens/users/AddUsers";
import DistrictList from "./Screens/events/district/DistrictList";
import EventCandidates from "./Screens/events/candidate/EventCandidates";
import TmpCandidates from "./Screens/candidates/TmpCandidates";
import UserProfilePage from "./Screens/events/UserProfilePage";
import Registration from "./Candidate/Registration";
import Profile from "./Candidate/Profile";
import SignIn from "./../src/Candidate/Login";
import MainLayoutCandidate from "./Candidate/Sidebar/MainLayoutCandidate";
import EditDetailsForm from "./Candidate/EditDetailsForm";
import { LoadingProvider } from "./Components/Layouts/LoadingProvider";
import List from "./Screens/events/List";
import AddCoachAuthorities from "./Screens/events/AddCoachAuthorities";
import AddCandidate from "./Screens/events/candidate/EventDashboard";
import SportWiseList from "./Screens/events/SportWiseList";
import CoachesAndAuthorize from "./Screens/events/CoachesAndAuthorize";
import UsersList from "./Screens/users/UsersList";
import EntryForm from "./Screens/events/candidate/EntryForm";
import EventDashboard from "./Screens/events/candidate/EventDashboard";
import ChangePassword from "./Screens/ChangePassword";
import PrivacyPolicy from "./Screens/privacyPolicy";
import TermsAndConditions from "./Screens/TermsAndConditions";
import UnVerifiedCandidate from "./Screens/candidates/UnVerifiedCandidate";
import PortalSettings from "./Screens/portalSettings";


function App() {
  return (
    <BrowserRouter>
      <LoadingProvider>
        <Routes>
          {/* logins **************************************** */}
          <Route path="/" element={<Login />} />
          <Route path="/user/login" element={<Login />} />
          <Route path="/candidate/registration" element={<Registration />} />
          <Route path="/candidate/login" element={<SignIn />} />
          <Route path="/candidate" element={<SignIn />} />

          {/* Change Password*************************************** */}
          <Route path='/change-password' element={<MainLayout page={ChangePassword} />} />

          {/* dashboard ************************************** */}
          <Route path="/dashboard" element={<MainLayout page={Dashboard} />} />

          {/* events **************************************** */}
          <Route path="/events" element={<MainLayout page={List} />} />
          <Route path="/event/add" element={<MainLayout page={AddEvents} />} />
          <Route
            path="/event/details/:id"
            element={<MainLayout page={EventDetails} />}
          />
          <Route
            path="/event/dashboard/:id/:district_id"
            element={<MainLayout page={EventDashboard} />}
          />
          <Route
            path="/event/candidate/entry-form/:id/:type/:district_id"
            element={<MainLayout page={EntryForm} />}
          />
          <Route
            path="/event/district-list/:id"
            element={<MainLayout page={DistrictList} />}
          />
          <Route
            path="/event/sport-wise/:id"
            element={<MainLayout page={SportWiseList} />}
          />
          <Route
            path="/event/coaches-and-managers/:id"
            element={<MainLayout page={CoachesAndAuthorize} />}
          />
          <Route
            path="/event/add/candidate/:id"
            element={<MainLayout page={AddCandidate} />}
          />
          <Route
            path="/event/candidates/:id"
            element={<MainLayout page={EventCandidates} />}
          />
          <Route
            path="/event/add/coach-and-authorities/:id/:district"
            element={<MainLayout page={AddCoachAuthorities} />}
          />

          {/* candidates **********************************************************/}
          <Route
            path="/candidates"
            element={<MainLayout page={Candidates} />}
          />
          <Route
            path="/candidate/profile"
            element={<MainLayoutCandidate page={Profile} />}
          />
          <Route
            path="/candidate/edit/profile"
            element={<MainLayoutCandidate page={EditDetailsForm} />}
          />
          <Route
            path="/user/profile"
            element={<MainLayout page={UserProfilePage} />}
          />
          <Route
            path="/candidate/list"
            element={<MainLayout page={Candidates} />}
          />
          <Route
            path="/candidate/tmp-list"
            element={<MainLayout page={TmpCandidates} />}
          />
          <Route
            path="/candidate/unverified"
            element={<MainLayout page={UnVerifiedCandidate} />}
          />

          {/* Users ************************************************* */}
          <Route path="/users" element={<MainLayout page={UsersList} />} />
          <Route path="/portal-setting" element={<MainLayout page={PortalSettings} />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
          <Route
            path="/users/add-users"
            element={<MainLayout page={AddUsers} />}
          />
          <Route path="*" element={<Login />} />
        </Routes>
      </LoadingProvider>
    </BrowserRouter>
  );
}
export default App;
