import { Box, Typography } from '@material-ui/core';
import React from 'react';

const PersonalDetailItem = ({ title, value }) => {
    return (
        <>
            <Box
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "10px",
                    alignItems: "center",
                }}
            >
                <Typography variant="subtitle1">{title}</Typography>
                <Typography
                    variant="subtitle1"
                    style={{ fontWeight: "bold" }}
                >
                    {value}
                </Typography>
            </Box>
            <Box
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "10px",
                    alignItems: "center",
                }}
            ></Box>
        </>
    );
};

export default PersonalDetailItem;
