import axios from "axios";
import { domain } from "./domain";

// Modified function to download a PDF file using Axios and call a callback with the received data
async function downloadPDF(event_id, district_id) {
    const headers = {
        "Content-Type": "multipart/form-data",
        "Authorization": localStorage.getItem("access_token"),
        "Refresh-token": localStorage.getItem("refresh_token")
    };

    try {
        const response = await axios({
            url: `${domain}user/event/report?event_id=${event_id}&district_id=${district_id}`,
            method: 'GET',
            responseType: 'arraybuffer',
            headers: headers
        });

        // Call the provided callback with the downloaded file's data
        handleDownloadedPDF(response.data);
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
}

// New function to handle the PDF data and trigger the save dialog
function handleDownloadedPDF(data) {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(currentDate.getDate()).padStart(2, '0');
    const hours = String(currentDate.getHours()).padStart(2, '0');
    const minutes = String(currentDate.getMinutes()).padStart(2, '0');

    const timestamp = `${year}-${month}-${day}_${hours}_${minutes}`;
    const fileName = `event_report${timestamp}.pdf`;
    // Create a Blob from the PDF data
    const blob = new Blob([data], { type: 'application/pdf' });

    // Create a URL for the Blob
    const url = window.URL.createObjectURL(blob);

    // Create a temporary anchor element and simulate a click to start the download
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName); // or any other extension
    document.body.appendChild(link);
    link.click();

    // Clean up by revoking the object URL and removing the anchor element
    setTimeout(() => {
        window.URL.revokeObjectURL(url);
        document.body.removeChild(link);
    }, 100); // Short delay to ensure the download starts before cleanup
}

export { downloadPDF, handleDownloadedPDF };