import DashboardOutlinedIcon from "@material-ui/icons/DashboardOutlined";
import EventNoteIcon from "@material-ui/icons/EventNote";
import PeopleIcon from "@material-ui/icons/People";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import LockOpenIcon from '@material-ui/icons/LockOpen';
import { ListItemIcon } from "@material-ui/core";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { Lock } from "@material-ui/icons";
const logoutUser = () => {
  localStorage.clear();
  window.location.href = "/";
};

const role = localStorage.getItem('role');
const isAdmin = role === 'admin';

const routes = [
  {
    name: "Dashboard",
    icon: DashboardOutlinedIcon,
    path: "/dashboard",
    badge: 0,
  },
  {
    name: "Events",
    icon: EventNoteIcon,
    path: "/events",
    badge: 0,
  },
  {
    name: "Candidates",
    icon: PeopleIcon,
    path: "/candidates",
    badge: 0,
    children: [
      {
        name: "Candidates",
        icon: ListItemIcon,
        path: "/candidate/list",
        badge: 0,
      },
      {
        name: "Unverified Candidates",
        icon: ListItemIcon,
        path: "/candidate/unverified",
        badge: 0,
      },
      {
        name: "Tmp Candidates",
        icon: ListItemIcon,
        path: "/candidate/tmp-list",
        badge: 0,
      },
    ],
  },
  isAdmin && {
    name: "Users",
    icon: PeopleIcon,
    path: "/users",
    badge: 0,
  },
  {
    name: "Change Password",
    icon: Lock,
    path: "/change-password",
    badge: 0,
  },
  isAdmin && {
    name: "Portal Settings",
    icon: Lock,
    path: "/portal-setting",
    badge: 0,
  },
  {
    name: "Logout",
    icon: ExitToAppIcon,
    path: "/",
    action: logoutUser,
    badge: 0,
  },
].filter(Boolean); // This will remove any 'false' entries from the array

export default routes;
