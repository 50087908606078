import React from "react";
import Wrapper from "../../Components/Wrapper";

import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import EventNoteIcon from "@material-ui/icons/EventNote";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import {
  Button,
  CardActionArea,
  CardMedia,
  Typography,
} from "@material-ui/core";

import { Link, useParams } from "react-router-dom";
import ArrowBackOutlinedIcon from "@material-ui/icons/ArrowBackOutlined";

const useStyles = makeStyles({
  table: {
    // minWidth: 650,
  },
  card: {
    marginBottom: 16,
    backgroundColor: "#3f51b5",
    color: "white",
  },
  actionButtons: {
    display: "flex",
    gap: 8, // Adjust the spacing between buttons
  },
  search: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid black",
    borderRadius: "5px",
  },
  dropDownField: {
    width: "200px",
    marginRight: "10px",
  },
  textAlignRight: {
    textAlign: "right",
  },
  root: {
    maxWidth: 345,
    background: "linear-gradient(to bottom right, #3f51b5, #9c27b0)",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)", // Adding a background shadow
    borderRadius: 10,
    overflow: "hidden",
  },
  media: {
    height: 200,
    objectFit: "cover",
  },
});

const EventDetails = () => {
  const classes = useStyles();
  const { id } = useParams();
  return (
    <div>
      <Wrapper>
        <Card className={classes.card}>
          <CardContent>
            <Grid
              spacing={10}
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item xs={12} sm={6}>
                <Typography variant="h4">
                  <EventNoteIcon /> Events
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} className={classes.textAlignRight}>
                <Link to="/events">
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<ArrowBackOutlinedIcon />}
                    size="large"
                  >
                    Back
                  </Button>
                </Link>
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={3}>
            <Card className={classes.root}>
              <CardActionArea>
                <Link to={`/event/candidates/${id}`}>
                  <CardMedia
                    className={classes.media}
                    image="https://t4.ftcdn.net/jpg/07/08/02/69/240_F_708026937_eQoIQirg2xFHOizAM8e027zHxah8Rnfp.jpghttps://as1.ftcdn.net/v2/jpg/06/99/50/48/1000_F_699504808_OgqzIAclQr71wkn3VkSKzLCF0iG5AuSK.jpg"
                    title="Fencing"
                  />

                  <CardContent>
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="h2"
                      align="center"
                      style={{ color: "#fff" }}
                    >
                      All Candidates
                    </Typography>
                    {/* <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                    style={{ color: "#eee" }}
                  >
                    Explore all candidates in our fencing tournament.
                  </Typography> */}
                  </CardContent>
                </Link>
              </CardActionArea>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Card className={classes.root}>
              <CardActionArea>
                <Link to={`/event/district-list/${id}`}>
                  <CardMedia
                    className={classes.media}
                    image="https://img.freepik.com/premium-vector/vector-silhouette-fencing-sports-person-flat-cutout-icon_601748-59176.jpg?size=626&ext=jpg&ga=GA1.1.646512935.1712210073&semt=sph"
                    title="Fencing"
                  />
                  <CardContent>
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="h2"
                      align="center"
                      style={{ color: "#fff" }}
                    >
                      District Wise List
                    </Typography>
                  </CardContent>
                </Link>
              </CardActionArea>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Card className={classes.root}>
              <CardActionArea>
                <Link to={`/event/sport-wise/${id}`}>
                  <CardMedia
                    className={classes.media}
                    image="https://img.freepik.com/premium-vector/vector-silhouette-fencing-sports-person-flat-cutout-icon_601748-59176.jpg?size=626&ext=jpg&ga=GA1.1.646512935.1712210073&semt=sph"
                    title="Fencing"
                  />
                  <CardContent>
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="h2"
                      align="center"
                      style={{ color: "#fff" }}
                    >
                      Sport Wise List
                    </Typography>
                  </CardContent>
                </Link>
              </CardActionArea>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Card className={classes.root}>
              <CardActionArea>
                <Link to={`/event/coaches-and-managers/${id}`}>
                  <CardMedia
                    className={classes.media}
                    image="https://t4.ftcdn.net/jpg/07/08/02/69/240_F_708026937_eQoIQirg2xFHOizAM8e027zHxah8Rnfp.jpg"
                    title="Fencing"
                  />
                  <CardContent>
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="h2"
                      align="center"
                      style={{ color: "#fff" }}
                    >
                      Coaches & Managers
                    </Typography>
                  </CardContent>
                </Link>
              </CardActionArea>
            </Card>
            {/* <ChartDemo /> */}
          </Grid>
        </Grid>
      </Wrapper>
    </div>
  );
};

export default EventDetails;
