import React, { useCallback, useEffect, useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { Card, FormControlLabel, Grid, Switch, TextField, Typography } from "@material-ui/core";
import { CheckBox, Save } from "@material-ui/icons";
import LockOpenIcon from '@material-ui/icons/LockOpen';
import Button from "@material-ui/core/Button";
import Wrapper from "../Components/Wrapper";
import { networkRequest } from "../utils/networkRequest";
import { apiGeneral } from "../utils/urls";
import { useLoading } from "../Components/Layouts/LoadingProvider";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "5px",
  },
  padding: {
    padding: theme.spacing(1.5),
  },
  textAlignRight: {
    textAlign: "right",
    padding: "10px",
  },
  bgPrimary: {
    backgroundColor: "#3f51b5",
    color: "white",
    display: "flex",
    alignItems: "center",
  },
  flexForm: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2), // Adds space between grid items vertically
    padding: "20px",
  },
  fullwidth: {
    width: "100%", // Makes the text fields full width
  },
  error: {
    color: "red",
  },
}));

const PortalSettings = () => {
  const classes = useStyles();
  const [checked, setChecked] = useState(false);
  const { setLoading } = useLoading();

  const isLoading = (b) => {
    setLoading(b)
  }

  const handleSwitch = (event) => {
    const e = event.target.checked
    networkRequest(apiGeneral.registrationPortalActive, isLoading, (resp) => {
      setChecked(e);
    }, 'post', { 'status': e })
  };

  useEffect(() => {
    networkRequest(apiGeneral.registrationPortalStatus, isLoading, (data) => {
      setChecked((data['data']['registration'] === "false") ? false : true)
    })
  }, []);


  return (
    <div>
      <Wrapper>
        <div className={classes.root}>
          <Card>
            <Grid>
              <Typography
                className={clsx(classes.padding, classes.bgPrimary)}
                variant="h6">
                <LockOpenIcon /> Portal Settings
              </Typography>
            </Grid>
            <Grid className={classes.flexForm}>
              <FormControlLabel
                control={
                  <Switch
                    checked={checked}
                    onChange={handleSwitch}
                    name="toggleSwitch"
                    color="primary"
                  />
                }
                label={`Registration On Portal is Currently ${(checked ? "Open" : "Closed")}.`}
              />
            </Grid>
          </Card>
        </div>
      </Wrapper>
    </div>
  );
};

export default PortalSettings;
