  import { Backdrop, Box, CircularProgress, Typography } from '@material-ui/core';
  import { makeStyles } from '@material-ui/core/styles';
  import React, { createContext, useContext, useState } from 'react';

  // Create LoadingContext
  const LoadingContext = createContext();

  const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  }));

  export function LoadingProvider({ children }) {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);

    return (
      <LoadingContext.Provider value={{ loading, setLoading }}>
        <Backdrop className={classes.backdrop} open={loading}>
          <center>
            <Box display="flex" flexDirection="column" alignItems="center">
              <CircularProgress color="inherit" />
              <Typography>Please Wait ...</Typography>
            </Box>
          </center>
        </Backdrop>
        {children}
      </LoadingContext.Provider>
    );
  };

  // Export the useContext hook for LoadingContext
  export const useLoading = () => useContext(LoadingContext);
