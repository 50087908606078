import React from 'react';

const PrivacyPolicy = () => {
    return (
        <div style={{ fontFamily: 'Arial, sans-serif', margin: '20px', lineHeight: '1.6' }}>
            <div style={{ marginTop: '100px' }}></div>
            <h1 style={{ color: '#2c3e50' }}>Mahafencing Association Policies</h1>
            
            <div className="section" id="privacy-policy" style={{ marginBottom: '40px' }}>
                <h2 style={{ color: '#2c3e50', marginTop: '40px' }}>Privacy Policy</h2>
                <p><strong>1. Introduction</strong></p>
                <p>This Privacy Policy outlines how the Mahafencing Association collects, uses, and protects your personal information.</p>
                <p><strong>2. Information Collection</strong></p>
                <p>2.1. We collect personal information such as name, contact details, and citizenship status during the registration process.</p>
                <p><strong>3. Use of Information</strong></p>
                <p>3.1. The information collected is used to facilitate event participation and communicate with participants.<br />
                    3.2. We share your data with event organizers to ensure your participation in events.</p>
                <p><strong>4. Data Protection</strong></p>
                <p>4.1. We implement appropriate security measures to protect your personal information.<br />
                    4.2. Despite our efforts, no method of transmission over the internet is 100% secure.</p>
                <p><strong>5. Data Sharing</strong></p>
                <p>5.1. We share your personal information with event organizers for the purpose of event participation.<br />
                    5.2. We do not sell or lease your personal information to third parties.</p>
                <p><strong>6. User Rights</strong></p>
                <p>6.1. Users have the right to access and correct their personal information.<br />
                    6.2. Users can request the deletion of their personal information, subject to legal requirements.</p>
                <p><strong>7. Changes to Privacy Policy</strong></p>
                <p>7.1. We may update this Privacy Policy from time to time. Users will be notified of any changes via email.</p>
                <p><strong>8. Contact Us</strong></p>
                <p>8.1. If you have any questions or concerns about this Privacy Policy, please contact us at [insert contact information].</p>
            </div>

            <div className="section" id="refund-policy" style={{ marginBottom: '40px' }}>
                <h2 style={{ color: '#2c3e50', marginTop: '40px' }}>Refund Policy</h2>
                <p><strong>1. Introduction</strong></p>
                <p>This Refund Policy outlines the terms regarding the non-refundable nature of registration fees for events organized by the Mahafencing Association.</p>
                <p><strong>2. Registration Fee</strong></p>
                <p>2.1. All registration fees paid to the Mahafencing Association are non-refundable.<br />
                    2.2. By completing the registration process, you acknowledge and agree to this non-refundable policy.</p>
                <p><strong>3. No Refunds</strong></p>
                <p>3.1. Once a user has registered for an event, no refunds will be issued under any circumstances.<br />
                    3.2. This policy applies to all participants, regardless of the reason for the refund request.</p>
                <p><strong>4. Event Cancellation</strong></p>
                <p>4.1. In the unlikely event that the Mahafencing Association cancels an event, no refunds will be provided. Participants will be notified of alternative arrangements, if any.</p>
                <p><strong>5. Contact Us</strong></p>
                <p>5.1. If you have any questions about our Refund Policy, please contact us at [insert contact information].</p>
            </div>
        </div>
    );
}

export default PrivacyPolicy;
