import React, { useCallback, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  FormLabel,
  Slider,
  TextField,
  Typography,
} from "@material-ui/core";
import { Link, Navigate, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import VisibilityIcon from "@material-ui/icons/Visibility";
import EditIcon from "@material-ui/icons/Edit";
import PhotoIcon from "@material-ui/icons/Photo";
import { networkRequest } from "../utils/networkRequest";
import { apiGeneral } from "../utils/urls";

import { domain } from "../utils/domain";
import Swal from "sweetalert2";
import DescriptionIcon from "@material-ui/icons/Description";
import Cropper from "react-easy-crop";
import { useLoading } from "../Components/Layouts/LoadingProvider";
import PersonalDetailItem from "../Components/PersonalDetailItem";
import { getFileExtension } from "../Extras/helpers";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles({
  table: {
    // minWidth: 650,
  },
  head: {
    backgroundColor: "#3f51b5",
    color: "white",
  },
  card: {
    marginBottom: 16,
    // backgroundColor:"red",
  },
  actionButtons: {
    display: "flex",
    gap: 8, // Adjust the spacing between buttons
  },

  textAlignRight: {
    textAlign: "right",
    backgroundColor: "#3f51b5",
    color: "white",
  },
  formControl: {
    minWidth: 120,
  },
  select: {
    width: "200px",
  },
  payNowbtn: {
    backgroundColor: "#ff5722",
    color: "#fff",
    padding: "10px 20px",
    fontSize: "16px",
    fontWeight: "bold",
  },
  viewDoc: {
    color: "#3f51b5",
    textDecoration: "underline",
    cursor: "pointer",
  },
  birthProofFlex: {
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
    gap: "10px",
  },
  helperText: {
    fontSize: "1rem",
    color: "orange",
    fontWeight: "bold",
  },
  errorhelperText: {
    color: "red",
    fontSize: "1rem",
  },
  fullScreenDialog: {
    "& .MuiDialog-paperFullScreen": {
      width: "100%",
      height: "100%",
    },
  },
});

const Wrapper = ({ children }) => <div style={{ padding: 16 }}>{children}</div>;

const Profile = ({ EditprofileData }) => {
  const classes = useStyles();
  const location = useLocation();
  const [value, setValue] = React.useState(0);
  const [profileData, setProfileData] = useState({});

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [open, setOpen] = useState(false);
  const [openEditPhotoModal, setOpenEditPhotoModal] = useState(false);
  const [editPhotoFormData, setEditPhotoFormData] = useState({
    uploadPhoto: "",
  });
  const [openEditDocModal, setOpenEditDocModal] = useState(false);
  const [editDocFormData, setEditDocFormData] = useState({
    uploadDocuments: "",
  });
  const [cropDialogOpen, setCropDialogOpen] = useState(false);
  const [imageSrc, setImageSrc] = useState("");
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [errors, setErrors] = useState({
    uploadPhoto: "",
    uploadDocuments: "",
  });

  //crop Image functions

  const handleDocumentEditChange = (event) => {
    const { name, files } = event.target;
    const file = files[0];

    setEditDocFormData((prevData) => ({ ...prevData, [name]: file }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const handleUploadEditedDocument = (e) => {
    e.preventDefault();

    const docFormDataToSend = new FormData();

    if (editDocFormData.uploadDocuments) {
      docFormDataToSend.append("document", editDocFormData.uploadDocuments);
    }

    setOpenEditDocModal(false); // Close the dialog
    networkRequest(
      apiGeneral.editDocuments,
      isLoading,
      handleEditDocResponse,
      "post",
      docFormDataToSend
    );

    setOpenEditDocModal(false);
  };
  const { loading, setLoading } = useLoading();
  const isLoading = (bool) => {
    setLoading(bool);
  };

  const handleEditDocResponse = (data) => {
    Swal.fire({
      icon: "success",
      title: "SUCCESS",
      text: JSON.stringify(data["message"]),
    });
    console.log(editDocFormData.uploadDocuments);

    const inputFileRef = document.getElementById("uploadDocuments");
    if (inputFileRef) {
      inputFileRef.value = "";
    }
    fetchProfileData();
    setOpenEditDocModal(false); // Close the dialog
  };
  const handleOpenEditDocModal = () => {
    setOpenEditDocModal(true);
  };
  const handleCloseEditDocModal = () => {
    setOpenEditDocModal(false);
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleCropSave = async () => {
    if (croppedAreaPixels) {
      const croppedImageURL = await getCroppedImg(imageSrc, croppedAreaPixels);
      setCroppedImage(croppedImageURL);
      setEditPhotoFormData((prevData) => ({
        ...prevData,
        uploadPhoto: croppedImageURL,
      }));

      setErrors((prevErrors) => ({ ...prevErrors, uploadPhoto: "" }));
      setCropDialogOpen(false);
    }
  };

  const handleCancelCropImage = () => {
    setEditPhotoFormData((prevData) => ({ ...prevData, uploadPhoto: "" }));
    console.log(editPhotoFormData.uploadPhoto);
    setCroppedImage(null);
    setCropDialogOpen(false);

    const inputFileRef = document.getElementById("uploadPhoto");
    if (inputFileRef) {
      inputFileRef.value = "";
    }
  };

  const getCroppedImg = async (imageSrc, pixelCrop) => {
    const image = await createImage(imageSrc);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;

    ctx.drawImage(
      image,
      pixelCrop.x,
      pixelCrop.y,
      pixelCrop.width,
      pixelCrop.height,
      0,
      0,
      pixelCrop.width,
      pixelCrop.height
    );

    return canvas.toDataURL("image/jpeg");
  };

  const createImage = (url) => {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener("load", () => resolve(image));
      image.addEventListener("error", (error) => reject(error));
      image.setAttribute("crossOrigin", "anonymous");
      image.src = url;
    });
  };

  const handleImageClick = () => {
    setOpen(true); // Open the dialog when the image is clicked
  };

  const handleClose = () => {
    setOpen(false); // Close the dialog
  };
  const handleCloseEditPhotoModal = () => {
    setOpenEditPhotoModal(false); // Close the dialog
    setEditPhotoFormData((prevData) => ({ ...prevData, uploadPhoto: "" }));
    console.log(editPhotoFormData.uploadPhoto);
    setCroppedImage(null);
    setCropDialogOpen(false);
    const inputFileRef = document.getElementById("uploadPhoto");
    if (inputFileRef) {
      inputFileRef.value = "";
    }
  };
  const handleOpenEditPhotoModal = () => {
    setOpenEditPhotoModal(true); // Close the dialog
  };

  const fetchProfileData = () => {
    networkRequest(apiGeneral.getCandidateProfile, isLoading, handleResponse);
  };
  const handleResponse = (response) => {
    setProfileData(response["data"]);

    console.log(response["data"]);
  };
  const makePayment = () => {
    networkRequest(
      apiGeneral.makePayment,
      isLoading,
      handleMakePaymentResponse
    );
  };
  const handleMakePaymentResponse = (response) => {
    window.location.replace(response.data);
  };

  const editProfilePhoto = () => {
    // Append the base64 image string directly if available
    setOpenEditPhotoModal(false);
    const formDataToSend = new FormData();
    if (editPhotoFormData.uploadPhoto) {
      // Strip the base64 header part
      const base64Image = editPhotoFormData.uploadPhoto.replace(
        /^data:image\/jpeg;base64,/,
        ""
      );
      formDataToSend.append("photo", base64Image);
    }
    networkRequest(
      apiGeneral.editProfilePhoto,
      isLoading,
      handleEditProfilePhotoResponse,
      "post",
      formDataToSend
    );

    setOpenEditPhotoModal(false);
  };

  const handleEditProfilePhotoResponse = (data) => {
    Swal.fire({
      icon: "success",
      title: "SUCCESS",
      text: JSON.stringify(data["message"]),
    });
    setEditPhotoFormData((prevData) => ({ ...prevData, uploadPhoto: "" }));
    console.log(editPhotoFormData.uploadPhoto);
    setCroppedImage(null);
    setCropDialogOpen(false);
    const inputFileRef = document.getElementById("uploadPhoto");
    if (inputFileRef) {
      inputFileRef.value = "";
    }
    fetchProfileData();
    setOpenEditPhotoModal(false); // Close the dialog
  };
  const handleFileChange = (event) => {
    console.log("File chooser called");
    setEditPhotoFormData((data) => ({ ...data, uploadPhoto: "" }));
    console.log(editPhotoFormData.uploadPhoto);
    const { name, files } = event.target;
    const file = files[0];
    const maxFileSize = 500 * 1024; // 500KB

    if (!file) {
      // No file selected, handle this case
      setCroppedImage(null);
      return;
    }

    const reader = new FileReader();
    reader.onload = () => {
      setImageSrc(reader.result);
      setCropDialogOpen(true);
    };
    reader.readAsDataURL(file);
  };

  const validateForm = () => {
    const errorsCopy = {}; // Initialize a new errors object

    // Validate district

    // Validate uploadPhoto
    if (!editPhotoFormData.uploadPhoto) {
      errorsCopy.uploadPhoto = "Photo is required";
    }

    // Validate uploadDocuments
    if (!editDocFormData.uploadDocuments) {
      errorsCopy.uploadDocuments = "Document is required";
    }

    // Update the state with errors, if any
    setErrors(errorsCopy);
  };

  useEffect(() => {
    validateForm();
    fetchProfileData();
  }, [editPhotoFormData, editDocFormData]);

  const baseURL = domain;
  const profileImageURL = `${baseURL}${profileData.photo}`;
  const documentImageURL = `${baseURL}${profileData.document}`;
  return (
    <Wrapper>
      <Card className={classes.card}>
        <CardContent>
          <Grid
            spacing={10}
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item xs={12} sm={12} className={classes.head}>
              <Typography variant="h5">
                <PermIdentityIcon /> Candidate Profile
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Card className={classes.card}>
        <CardContent>
          <Grid spacing={2} container>
            <Grid item xs={12} sm={4}>
              <Card>
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <CardContent>
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={profileImageURL}
                        alt=""
                        height={"100px"}
                        width={"100px"}
                        style={{ borderRadius: "50px" }}
                      />
                      <Typography variant="h6">{profileData.name}</Typography>
                    </Box>

                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography variant="subtitle1">
                        <b> Verification Status:</b>
                      </Typography>
                      <Typography
                        variant="h6"
                        style={{
                          color:
                            profileData.verification_status === "state"
                              ? "#1976d2"
                              : profileData.verification_status === "pending"
                                ? "orange"
                                : profileData.verification_status === "rejected"
                                  ? "red"
                                  : profileData.verification_status === "district"
                                    ? "orange"
                                    : "inherit",
                          textTransform: "uppercase",
                        }}
                      >
                        <b>
                          {profileData.verification_status === "state"
                            ? "VERIFIED"
                            : profileData.verification_status}
                        </b>
                      </Typography>
                    </Box>
                    <PersonalDetailItem title="Gender:" value={profileData.gender} />
                    {(profileData.mfa_id !== null) ?
                      <PersonalDetailItem title="MFA ID:" value={profileData.mfa_id} />
                      : <PersonalDetailItem title="TMP REG ID:" value={profileData.id} />}
                    <PersonalDetailItem title="Contact No.:" value={profileData.contact} />
                  </CardContent>
                </Box>
              </Card>
              <Card style={{ marginTop: "10px" }}>
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <CardContent>
                    <Typography variant="h6">Other Details</Typography>
                    <PersonalDetailItem
                      title="Aadhaar No."
                      value={profileData.aadhaar}
                    />
                    <Typography variant="subtitle1">Birth Proof</Typography>
                    <Box className={classes.birthProofFlex}>
                      <Typography
                        variant="subtitle1"
                        style={{ fontWeight: "bold" }}
                      >
                        <Button
                          className={classes.viewDoc}
                          onClick={handleImageClick}
                          variant="contained"
                          color="default"
                        >
                          <VisibilityIcon />
                        </Button>
                        <Dialog open={open} onClose={handleClose}>
                          <DialogContent>
                            {(getFileExtension(documentImageURL) !== 'pdf') ? (
                              <img
                                src={documentImageURL}
                                alt=""
                                style={{ maxWidth: "100%", height: "auto" }}
                              />) : (
                              <object width="600" height="5 00" data={documentImageURL} type="application/pdf">   </object>)}
                            <DialogActions>
                              <Button onClick={handleClose}>Close</Button>
                            </DialogActions>
                          </DialogContent>
                        </Dialog>
                      </Typography>
                    </Box>
                  </CardContent>
                </Box>
              </Card>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Card>
                <CardContent>
                  <Typography variant="h6">Personal Details</Typography>
                  <PersonalDetailItem title="Name :" value={profileData.name} />
                  <PersonalDetailItem title="Mother Name:" value={profileData.mother} />
                  <PersonalDetailItem title="Email Address:" value={profileData.email} />
                  <PersonalDetailItem title="Contact No.:" value={profileData.contact} />
                  <PersonalDetailItem title="Date Of Birth:" value={profileData.dob} />
                  <PersonalDetailItem title="District:" value={profileData.district} />
                  <PersonalDetailItem title="Address:" value={''} />
                  <Typography><b>{profileData.address}</b></Typography>
                </CardContent>
              </Card>
              <Box>
                <div className={classes.root}>
                  <AppBar
                    position="static"
                    style={{ backgroundColor: "#fff", color: "black" }}
                  >
                  </AppBar>
                  {/* General Details */}
                  <Card style={{ marginTop: "10px" }}>
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <CardContent>
                        {profileData.payment_status === "unpaid" && (
                          <Typography color="error" variant="body1">
                            Note: Kindly Make Payment To Submit Your Application
                            For Verification
                          </Typography>
                        )}
                        <Typography variant="h6">Payment Details</Typography>
                        <Box
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            gap: "10px",
                            alignItems: "center",
                          }}
                        >
                          <Typography variant="subtitle1">
                            Payment ID
                          </Typography>
                          <Typography
                            variant="subtitle1"
                            style={{ fontWeight: "bold" }}
                          >
                            {profileData.payment_id}
                          </Typography>
                        </Box>
                        <Box
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            gap: "10px",
                            alignItems: "center",
                          }}
                        >
                          <Typography variant="subtitle1">
                            Payment Status
                          </Typography>
                          <Typography
                            variant="subtitle1"
                            style={{
                              fontWeight: "bold",
                              color:
                                profileData.payment_status === "unpaid"
                                  ? "#ff5722"
                                  : "green",
                            }}
                          >
                            {profileData.payment_status === "Credit"
                              ? "Successful"
                              : profileData.payment_status}
                          </Typography>
                          {(profileData.payment_status === "pending" || profileData.payment_status === "Failed") && (
                            <>
                              <Button
                                variant="contained"
                                color="primary"
                                className={classes.payNowbtn}
                                onClick={makePayment}
                                type="submit"
                              >
                                Pay Now
                              </Button>
                            </>
                          )}
                        </Box>
                      </CardContent>
                    </Box>
                    <CardContent>
                      {profileData.verification_status === "rejected" && (
                        <Typography color="error">
                          <b>
                            Reason Of Rejection :{" "}
                            {profileData.note.toUpperCase()}
                          </b>
                        </Typography>
                      )}
                      <br />
                      {(profileData.verification_status === "pending" ||
                        profileData.verification_status === "rejected") && (
                          <>
                            <Grid container spacing={2}>
                              <Grid item sm={4} xs={6}>
                                <Link
                                  to={"/candidate/edit/profile"}
                                  state={profileData}
                                >
                                  <Button variant="contained" color="primary">
                                    <EditIcon style={{ marginRight: "5px" }} />{" "}
                                    Edit Details
                                  </Button>
                                </Link>
                              </Grid>
                              <Grid item sm={4} xs={6}>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={handleOpenEditDocModal}
                                >
                                  <DescriptionIcon
                                    style={{ marginRight: "5px" }}
                                  />{" "}
                                  Upload Document
                                </Button>
                              </Grid>
                              <Grid item sm={4} xs={6}>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={handleOpenEditPhotoModal}
                                >
                                  <PhotoIcon style={{ marginRight: "5px" }} />
                                  Upload Photo
                                </Button>
                              </Grid>
                            </Grid>
                          </>
                        )}
                    </CardContent>
                    {/* Upload Photo Modal */}
                    <Dialog
                      open={openEditPhotoModal}
                      onClose={handleCloseEditPhotoModal}
                    >
                      <DialogContent>
                        <Grid>
                          <FormLabel>Upload Photo :</FormLabel>
                          <TextField
                            variant="outlined"
                            fullWidth
                            type="file"
                            id="uploadPhoto"
                            name="uploadPhoto"
                            onChange={handleFileChange}
                            inputProps={{
                              accept: "image/*",
                            }}
                          />
                          {errors.uploadPhoto && (
                            <FormHelperText className={classes.errorhelperText}>
                              {errors.uploadPhoto}
                            </FormHelperText>
                          )}
                          <FormHelperText className={classes.helperText}>
                            Note: Photo must be in Image format and passport
                            size and max size 500KB.
                          </FormHelperText>
                        </Grid>
                        {croppedImage && (
                          <>
                            <Grid item xs={10}>
                              <img
                                src={croppedImage}
                                alt="Cropped"
                                style={{ maxWidth: "100%" }}
                              />
                            </Grid>
                            <Grid item xs={2}>
                              <Button
                                variant="contained"
                                onClick={handleCancelCropImage}
                              >
                                X
                              </Button>
                            </Grid>
                          </>
                        )}
                        <DialogActions>
                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={handleCloseEditPhotoModal}
                          >
                            Close
                          </Button>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={editProfilePhoto}
                          >
                            Save
                          </Button>
                        </DialogActions>
                      </DialogContent>
                    </Dialog>

                    {/* Upload Doc Modal */}
                    <Dialog
                      open={openEditDocModal}
                      onClose={handleCloseEditDocModal}
                    >
                      <DialogContent>
                        <Grid item xs={12}>
                          <FormLabel>Edit Document</FormLabel>
                          <TextField
                            variant="outlined"
                            fullWidth
                            type="file"
                            id="uploadDocuments"
                            name="uploadDocuments"
                            onChange={handleDocumentEditChange}
                          />
                          {errors.uploadDocuments && (
                            <FormHelperText className={classes.errorhelperText}>
                              {errors.uploadDocuments}
                            </FormHelperText>
                          )}
                          <FormHelperText className={classes.helperText}>
                            Note: Document must be in Image/PDF format and max size
                            500KB.
                          </FormHelperText>
                          <FormHelperText className={classes.helperText}>
                            Note: Date of Birth proof/ Aadhar card/ Birth
                            Certificate/ passport/ school leaving certificate.
                            must be in Image format and max size 500KB.
                          </FormHelperText>
                        </Grid>
                        <DialogActions>
                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={handleCloseEditDocModal}
                          >
                            Close
                          </Button>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={handleUploadEditedDocument}
                          >
                            Save
                          </Button>
                        </DialogActions>
                      </DialogContent>
                    </Dialog>
                  </Card>
                </div>
              </Box>
            </Grid>
          </Grid>
          {/* Crop Image Dialog */}
          <Dialog
            open={cropDialogOpen}
            onClose={() => setCropDialogOpen(false)}
            aria-labelledby="form-dialog-title"
            className={classes.fullScreenDialog}
            fullWidth
            maxWidth="sm"
          >
            <DialogTitle id="form-dialog-title">Crop Image</DialogTitle>
            <DialogContent>
              <div
                style={{
                  position: "relative",
                  width: "100%",
                  height: 400,
                  background: "#333",
                }}
              >
                <Cropper
                  image={imageSrc}
                  crop={crop}
                  zoom={zoom}
                  aspect={1}
                  onCropChange={setCrop}
                  onZoomChange={setZoom}
                  onCropComplete={onCropComplete}
                />
              </div>
              <div style={{ marginTop: 16 }}>
                <Typography gutterBottom>Zoom</Typography>
                <Slider
                  value={zoom}
                  min={1}
                  max={3}
                  step={0.1}
                  aria-labelledby="Zoom"
                  onChange={(e, zoom) => setZoom(zoom)}
                />
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setCropDialogOpen(false)} color="primary">
                Cancel
              </Button>
              <Button onClick={handleCropSave} color="primary">
                Save
              </Button>
            </DialogActions>
          </Dialog>
        </CardContent>
      </Card>
    </Wrapper >
  );
};

export default Profile;
