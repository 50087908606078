import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Group from "@material-ui/icons/Group";
import {
  Box,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import {
  Edit,
  FileCopyOutlined,
  PictureAsPdf,
  PrintOutlined,
} from "@material-ui/icons";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { apiGeneral } from "../../utils/urls";
import { networkRequest } from "../../utils/networkRequest";
import { domain } from "../../utils/domain";
import Swal from "sweetalert2";
import { useLoading } from "../../Components/Layouts/LoadingProvider";
import { Pagination } from "@material-ui/lab";
import SearchIcon from "@material-ui/icons/Search";
import axios from "axios";
import { getFileExtension } from "../../Extras/helpers";

const useStyles = makeStyles({
  table: {
    // minWidth: 650,
  },
  card: {
    marginBottom: 16,
    backgroundColor: "#3f51b5",
    color: "white",
  },
  actionButtons: {
    // display: "flex",
    // gap: 8, // Adjust the spacing between buttons
  },
  search: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid black",
    borderRadius: "5px",
    color: "black",
    padding: "5px",
    backgroundColor: "white",
  },
  dropDownField: {
    width: "200px",
    marginRight: "10px",
  },
  textAlignRight: {
    textAlign: "right",
  },
});

const Wrapper = ({ children }) => <div style={{ padding: 16 }}>{children}</div>;

export default function Candidates() {
  const classes = useStyles();

  const { setLoading } = useLoading();
  const isLoading = (bool) => {
    setLoading(bool);
  };
  const [candidateList, setCandidateList] = useState([]);
  const [tablePages, setTablePages] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [districts, setDistricts] = useState([]);
  const [totalCandidate,setTotalCandidate]=useState(0);
  const [role, setRole] = useState('user')

  const handlePagination = (e, v) => {
    setActivePage(v);
  };

  const fetchCandidateListData = () => {
    networkRequest(
      `${apiGeneral.GetCandidateList}/${activePage}`,
      isLoading,
      handleResponse,
      'get',
      { search: searchQuery, district_id: selectedDistrict }
    );
  };
  const handleResponse = (response) => {
    setCandidateList(response["data"]);
    setTablePages(response["total_pages"]);
    setTotalCandidate(response["total_candidates"]);
  };
  useEffect(() => {
    fetchCandidateListData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePage]);

  const [searchQuery, setSearchQuery] = useState("");
  const [selectedDistrict, setSelectedDistrict] = useState("all");

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setActivePage(1)
      networkRequest(
        `${apiGeneral.GetCandidateList}/${activePage}`,
        isLoading,
        handleResponse,
        'get',
        { search: searchQuery, district_id: selectedDistrict }
      );
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery, selectedDistrict]);
  // doc popup
  const [showDocPopup, setShowDocPopup] = useState(false);
  const [imagePopUp, setImagePopUp] = useState("");

  const showDocumentPopup = (url) => {
    setImagePopUp(domain + "/" + url);
    setShowDocPopup(true);
  };


  useEffect(() => {
    const role = localStorage.getItem('role');
    setRole(role)
    const fetchDistricts = async () => {
      if (role !== 'admin') {
        setDistricts([{ district: "Not Available", id: 0 }])
        return;
      }
      setLoading(true)
      try {
        const response = await axios.get(
          apiGeneral.getDistricts
        );
        const district = [{ district: "All", id: 'all' }]
        district.push(...response.data['data'])
        setDistricts(district);
      } catch (error) {
      } finally {
        setLoading(false)
      }
    };

    fetchDistricts();
  }, []);

  return (
    <Wrapper>
      <Card className={classes.card}>
        <CardContent>
          <Grid
            spacing={1}
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item xs={12} sm={9}>
              <Typography variant="h5">
                <Group /> Candidates List
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Grid
        spacing={1}
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid
          item
          xs={12}
          sm={6}
          style={{
            color: "white",
            marginBottom: "10px",
          }}
        >
          <ButtonGroup
            // color="warning"
            aria-label="outlined primary button group"
          >
            <Button style={{ backgroundColor: "#1181E6", color: "white" }}>
              <FileCopyOutlined />
            </Button>
            <Button style={{ backgroundColor: "#1181E6", color: "white" }}>
              <PictureAsPdf />
            </Button>
            <Button style={{ backgroundColor: "#1181E6", color: "white" }}>
              <PrintOutlined />
            </Button>
          </ButtonGroup>
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          style={{
            marginBottom: "10px",
          }}
        >
          <TextField
            id="outlined-select-DivisionClasses-native"
            select
            name="district_id"
            label="All District"
            onChange={(e) => { setSelectedDistrict(e.target.value) }}
            SelectProps={{
              native: true,
            }}
            variant="outlined"
            className={classes.dropDownField}
            size="small"
          >
            {districts.map((district) => (
              <option key={district.id} value={district.id}>
                {district.district}
              </option>
            ))}
          </TextField>

          <TextField
            variant="outlined"
            label="Search  Candidate"
            size="small"
            onKeyUp={(e) => {
              setSearchQuery(e.target.value);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>

      <Grid container spacing={1}>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Photo</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>DOB</TableCell>
                <TableCell>Gender</TableCell>
                <TableCell>Contact</TableCell>
                <TableCell>Aadhaar</TableCell>
                <TableCell>Mother </TableCell>
                <TableCell>District</TableCell>
                <TableCell>Address</TableCell>
                <TableCell align="left">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {candidateList.map((row) => (
                <TableRow key={row.id}>
                  <TableCell component="th" scope="row">
                    MF{row.mf_id}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <img
                      src={`${domain}/${row.photo}`}
                      alt={`${row.name}'s profile`}
                      width={100}
                    />
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.email}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.dob}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.gender}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.contact}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.aadhaar}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.mother}
                  </TableCell>
                  <TableCell align="left">{row.district}</TableCell>
                  <TableCell component="th" scope="row">
                    {row.address}
                  </TableCell>
                  <TableCell align="left" className={classes.actionButtons}>
                    <Tooltip title="View Document">
                      <Button
                        variant="contained"
                        onClick={() => {
                          showDocumentPopup(row.document);
                        }}
                        color="primary"
                        style={{
                          marginBottom: "5px",

                          color: "white",
                        }}
                      >
                        {<VisibilityIcon style={{ color: "white" }} />}
                      </Button>
                    </Tooltip>
                    {(role === 'admin') && <Tooltip title="Send For Correction">
                      <Button
                        variant="contained"
                        onClick={() => {
                          Swal.fire({
                            title: 'Are you sure? You Want to Reject this application?',
                            text: "You won't be able to revert this!",
                            icon: 'warning',
                            showCancelButton: true,
                            confirmButtonColor: '#3085d6',
                            cancelButtonColor: '#d33',
                            confirmButtonText: 'Yes, delete it!'
                          }).then((result) => {
                            if (result.isConfirmed) {
                              networkRequest(`${apiGeneral.candidateCancel}/${row.id}`, isLoading, (response) => {
                                fetchCandidateListData();
                                Swal.fire(
                                  'Deleted!',
                                  response.data['message'],
                                  'success'
                                )
                              })
                            }
                          })
                        }}
                        color="primary"
                        style={{
                          marginBottom: "5px",

                          color: "white",
                        }}
                      >
                        {<Edit style={{ color: "white" }} />}
                      </Button>
                    </Tooltip>}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
    
          <Grid
            container
            spacing={0}
            style={{
              display: "flex",
              justifyContent: "flex-end",
              padding: "20px",
            }}
          >
            <Grid container spacing={0} xs={12} md={8}>
              <Pagination
                count={tablePages}
                color="primary"
                style={{ marginRight: "10px" }}
                variant="outlined"
                shape="rounded"
                onChange={handlePagination}
              />
            </Grid>
            <Grid container spacing={0} xs={12} md={4}>
             <Typography >Total Candidate : {totalCandidate}</Typography>
            </Grid>
          </Grid>
      </Grid>
      <Dialog open={showDocPopup}>
        <DialogContent>
          {(getFileExtension(imagePopUp) !== 'pdf') ? (
            <img
              src={imagePopUp}
              alt=""
              style={{ maxWidth: "100%", height: "auto" }}
            />) : (
            <object width="600" height="500" data={imagePopUp} type="application/pdf">   </object>)}
          <DialogActions>
            <Button
              onClick={() => {
                setShowDocPopup(false);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </Wrapper>
  );
}
