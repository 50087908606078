import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import EventNoteIcon from "@material-ui/icons/EventNote";
import {
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  InputLabel,
  TextField,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Cancel, Save } from "@material-ui/icons";
import { useEffect, useState } from "react";
import { useLoading } from "../../Components/Layouts/LoadingProvider";
import { networkRequest } from "../../utils/networkRequest";
import { apiGeneral } from "../../utils/urls";
import Swal from "sweetalert2";
import { domain } from "../../utils/domain";
const useStyles = makeStyles((theme) => ({
  card: {
    marginBottom: 16,
    display: "flex",
    gap: 8,
  },
  margin: {
    margin: theme.spacing(2),
  },
  textAlignRight: {
    textAlign: "right",
  },
  bgPrimary: {
    width: "100%",
    backgroundColor: "#3f51b5",
    color: "white",
  },
  padding: {
    padding: theme.spacing(2),
  },
  textField: {
    width: "60ch",
  },
  textField20ch: {
    width: "20ch",
  },
  errorHelperText: {
    color: "red",
    fontSize: "1rem",
  },
}));
const Wrapper = ({ children }) => <div style={{ padding: 16 }}>{children}</div>;
export default function AddCoachAuthorities() {
  const classes = useStyles();

  const { loading, setLoading } = useLoading();

  const isLoading = (bool) => {
    setLoading(bool);
  };

  useEffect(() => {
    setLoading(false);
  }, []);
  // const [errors, setErrors] = useState({});
  const { id, district, district_id } = useParams();
  console.log("event" + id, "district id" + district);
  const [formData, setFormData] = useState({
    event_id: id,
    district_id: district,
    boys_coach_name: "",
    boys_coach_contact: "",
    boys_manager_name: "",
    boys_manager_contact: "",
    girls_coach_name: "",
    girls_coach_contact: "",
    girls_manager_name: "",
    girls_manager_contact: "",
    authorised_name: "",
    authorised_contact: "",
    signature: "",
    association: "",
  });

  const [errors, setErrors] = useState({
    signature: "",
  });
  const navigate = useNavigate();

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };
  const handleDocumentInputChange = (event) => {
    const { name, files } = event.target;
    const file = files[0];

    setFormData((prevData) => ({ ...prevData, [name]: file }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };
  const validateForm = () => {
    const errorsCopy = {}; // Initialize a new errors object
    // Boys Coach name
    if (
      formData.boys_coach_name === "" ||
      formData.boys_coach_name.length < 3
    ) {
      errorsCopy.boys_coach_name = "Boys Coach Name is Required";
    }
    // Boys Coach contact
    if (
      formData.boys_coach_contact === "" ||
      formData.boys_coach_contact.length < 10 ||
      formData.boys_coach_contact.length > 10
    ) {
      errorsCopy.boys_coach_contact = "Boys Coach contact is Required";
    }
    // Boys Manager name
    if (
      formData.boys_manager_name === "" ||
      formData.boys_manager_name.length < 3
    ) {
      errorsCopy.boys_manager_name = "Boys Coach Name is Required";
    }
    // Boys Manager contact
    if (
      formData.boys_manager_contact === "" ||
      formData.boys_manager_contact.length < 10 ||
      formData.boys_manager_contact.length > 10
    ) {
      errorsCopy.boys_manager_contact = "Boys Manager contact is Required";
    }
    // Girls Coach name
    if (
      formData.girls_coach_name === "" ||
      formData.girls_coach_name.length < 3
    ) {
      errorsCopy.girls_coach_name = "Girls Coach Name is Required";
    }
    // Girls Coach contact
    if (
      formData.girls_coach_contact === "" ||
      formData.girls_coach_contact.length < 10 ||
      formData.girls_coach_contact.length > 10
    ) {
      errorsCopy.girls_coach_contact = "Girls Coach contact is Required";
    }
    // Girls Manager name
    if (
      formData.girls_manager_name === "" ||
      formData.girls_manager_name.length < 3
    ) {
      errorsCopy.girls_manager_name = "Girls Coach Name is Required";
    }
    // Girls Manager contact
    if (
      formData.girls_manager_contact === "" ||
      formData.girls_manager_contact.length < 10 ||
      formData.girls_manager_contact.length > 10
    ) {
      errorsCopy.girls_manager_contact = "Girls Manager contact is Required";
    }
    // Authorised Person Name
    if (
      formData.authorised_name === "" ||
      formData.authorised_name.length < 3
    ) {
      errorsCopy.authorised_name = "Authorised Person  Name is Required";
    }
    // Authorised Person Name
    if (formData.association === "" || formData.association.length < 3) {
      errorsCopy.association = "Association Name is Required";
    }
    // Association Contact
    if (
      formData.authorised_contact === "" ||
      formData.authorised_contact.length < 10 ||
      formData.authorised_contact.length > 10
    ) {
      errorsCopy.authorised_contact = "Authorised Contact is Required";
    }
    // Validate upload Image
    if (formData.signature === "") {
      errorsCopy.signature = "Document is required";
    }
    setErrors(errorsCopy);
  };
  useEffect(
    (e) => {
      validateForm();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formData]
  );

  const handleSubmit = () => {
    if (Object.keys(errors).length === 0) {
      const formDataToSend = new FormData();
      Object.entries(formData).forEach(([k, v]) => {
        formDataToSend.append(k, v);
      });
      networkRequest(
        apiGeneral.addCoachesAndAuthorities,
        isLoading,
        handleAddCoachesSuccess,
        "post",
        formDataToSend
      );
    }
  };
  const handleAddCoachesSuccess = (response) => {
    Swal.fire({
      icon: "success",
      title: "SUCCESS",
      text: response["message"],
    });
    navigate(`/event/dashboard/${id}/${district}`);
  };

  const fetchCoachAndAuthorities = () => {
    networkRequest(
      `${apiGeneral.getCoachesAndAuthorities}/${id}/${district}`,
      isLoading,
      handleResponse);
  };
  const handleResponse = (response) => {
    if (response["data"].length !== 0)
      setFormData(response["data"]);
  };
  useEffect(() => fetchCoachAndAuthorities, []);

  return (
    <Wrapper>
      <Card className={classes.card}>
        <Grid item xs={12} sm={12}>
          <Typography
            className={clsx(classes.padding, classes.bgPrimary)}
            variant="h4"
          >
            <EventNoteIcon /> Add Coach And Authorities Information
          </Typography>
          <Card>
            <Typography
              variant="h6"
              style={{
                color: "blue",
                fontSize: "1rem",
                marginTop: "1rem",
                marginLeft: "1rem",
              }}
            >
              Boys Team Details
            </Typography>
            <FormControl className={clsx(classes.margin, classes.textField)}>
              <InputLabel htmlFor="standard-adornment-password">
                Boys Coach Name
              </InputLabel>
              <Input
                id="standard-adornment-Roll-number"
                type="text"
                name="boys_coach_name"
                value={formData.boys_coach_name}
                onChange={handleInputChange}
              />
              {errors.boys_coach_name && (
                <FormHelperText className={classes.errorHelperText}>
                  {errors.boys_coach_name}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl className={clsx(classes.margin, classes.textField)}>
              <InputLabel htmlFor="standard-adornment-password">
                Boys Coach Contact
              </InputLabel>
              <Input
                id="standard-adornment-Roll-number"
                type="number"
                name="boys_coach_contact"
                value={formData.boys_coach_contact}
                onChange={handleInputChange}
              />
              {errors.boys_coach_contact && (
                <FormHelperText className={classes.errorHelperText}>
                  {errors.boys_coach_contact}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl className={clsx(classes.margin, classes.textField)}>
              <InputLabel htmlFor="standard-adornment-password">
                Boys Manager Name
              </InputLabel>
              <Input
                id="standard-adornment-Roll-number"
                type="text"
                name="boys_manager_name"
                value={formData.boys_manager_name}
                onChange={handleInputChange}
              />
              {errors.boys_manager_name && (
                <FormHelperText className={classes.errorHelperText}>
                  {errors.boys_manager_name}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl className={clsx(classes.margin, classes.textField)}>
              <InputLabel htmlFor="standard-adornment-password">
                Boys Manager Contact
              </InputLabel>
              <Input
                id="standard-adornment-Roll-number"
                type="number"
                name="boys_manager_contact"
                value={formData.boys_manager_contact}
                onChange={handleInputChange}
              />
              {errors.boys_manager_contact && (
                <FormHelperText className={classes.errorHelperText}>
                  {errors.boys_manager_contact}
                </FormHelperText>
              )}
            </FormControl>
            <Typography
              variant="h6"
              style={{
                color: "blue",
                fontSize: "1rem",
                marginTop: "1rem",
                marginLeft: "1rem",
              }}
            >
              Girls Team Details
            </Typography>
            <FormControl className={clsx(classes.margin, classes.textField)}>
              <InputLabel htmlFor="standard-adornment-password">
                Girls Coach Name
              </InputLabel>
              <Input
                id="standard-adornment-Roll-number"
                type="text"
                name="girls_coach_name"
                value={formData.girls_coach_name}
                onChange={handleInputChange}
              />
              {errors.girls_coach_name && (
                <FormHelperText className={classes.errorHelperText}>
                  {errors.girls_coach_name}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl className={clsx(classes.margin, classes.textField)}>
              <InputLabel htmlFor="standard-adornment-password">
                Girls Coach Contact
              </InputLabel>
              <Input
                id="standard-adornment-Roll-number"
                type="number"
                name="girls_coach_contact"
                value={formData.girls_coach_contact}
                onChange={handleInputChange}
              />
              {errors.girls_coach_contact && (
                <FormHelperText className={classes.errorHelperText}>
                  {errors.girls_coach_contact}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl className={clsx(classes.margin, classes.textField)}>
              <InputLabel htmlFor="standard-adornment-password">
                Girls Manager Name
              </InputLabel>
              <Input
                id="standard-adornment-Roll-number"
                type="text"
                name="girls_manager_name"
                value={formData.girls_manager_name}
                onChange={handleInputChange}
              />
              {errors.girls_manager_name && (
                <FormHelperText className={classes.errorHelperText}>
                  {errors.girls_manager_name}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl className={clsx(classes.margin, classes.textField)}>
              <InputLabel htmlFor="standard-adornment-password">
                Girls Manager Contact
              </InputLabel>
              <Input
                id="standard-adornment-Roll-number"
                type="number"
                name="girls_manager_contact"
                value={formData.girls_manager_contact}
                onChange={handleInputChange}
              />
              {errors.girls_manager_contact && (
                <FormHelperText className={classes.errorHelperText}>
                  {errors.girls_manager_contact}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl className={clsx(classes.margin, classes.textField)}>
              <InputLabel htmlFor="standard-adornment-password">
                Authorised Person Name
              </InputLabel>
              <Input
                id="standard-adornment-Roll-number"
                type="text"
                name="authorised_name"
                value={formData.authorised_name}
                onChange={handleInputChange}
              />
              {errors.authorised_name && (
                <FormHelperText className={classes.errorHelperText}>
                  {errors.authorised_name}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl className={clsx(classes.margin, classes.textField)}>
              <InputLabel htmlFor="standard-adornment-password">
                Association Name
              </InputLabel>
              <Input
                id="standard-adornment-Roll-number"
                type="text"
                name="association"
                value={formData.association}
                onChange={handleInputChange}
              />
              {errors.association && (
                <FormHelperText className={classes.errorHelperText}>
                  {errors.association}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl className={clsx(classes.margin, classes.textField)}>
              <InputLabel htmlFor="standard-adornment-password">
                Association Contact
              </InputLabel>
              <Input
                id="standard-adornment-Roll-number"
                type="text"
                name="authorised_contact"
                value={formData.authorised_contact}
                onChange={handleInputChange}
              />
              {errors.authorised_contact && (
                <FormHelperText className={classes.errorHelperText}>
                  {errors.authorised_contact}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl className={clsx(classes.margin, classes.textField)}>
              <FormLabel>
                Authorised Person Signature(In Image Format)
              </FormLabel>
              <TextField
                variant="outlined"
                fullWidth
                type="file"
                id="uploadDocuments"
                name="signature"
                onChange={handleDocumentInputChange}
                inputProps={{
                  accept: "image/*",
                }}
              />
              {errors.signature && (
                <FormHelperText className={classes.errorHelperText}>
                  {errors.signature}
                </FormHelperText>
              )}
              {formData.signature && (<img src={`${domain}/${formData.signature}`} alt="signature" height={100} width={200}/>)}
            </FormControl>
            <Card>
              <Button
                variant="contained"
                color="Primary"
                size="large"
                className={clsx(classes.margin, classes.button)}
                startIcon={<Save />}
                type="submit"
                onClick={handleSubmit}
              >
                Save
              </Button>
              <Button
                onClick={() => { navigate(-1) }}
                variant="contained"
                color="Default"
                size="large"
                className={clsx(classes.margin, classes.button)}
                startIcon={<Cancel />}
              >
                Cancel
              </Button>
            </Card>
          </Card>
        </Grid>
      </Card>
    </Wrapper>
  );
}
