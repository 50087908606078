import React, { useEffect, useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";

import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { Chip, FormHelperText } from "@material-ui/core";
import axios from "axios";
import { apiGeneral } from "../utils/urls";
import Swal from "sweetalert2";
import { Link, useNavigate } from "react-router-dom";
import { useLoading } from "../Components/Layouts/LoadingProvider";
function Copyright() {
  return (
    <>
      <Typography variant="body2" color="textSecondary" align="center">
        {"Copyright © "}
        <Link
          style={{ textDecoration: "none", color: "inherit" }}
          color="inherit"
          href="#"
        >
          Mahafencing.in
        </Link>{" "}
        {new Date().getFullYear()}
      </Typography>
      <Typography variant="body2" color="textSecondary" align="center">
        {"Developed By © "}
        <Link
          style={{ textDecoration: "none", color: "inherit" }}
          color="inherit"
          to="https://sublimetechnologies.in/"
          target="_blank"
        >
          Sublime Technologies
        </Link>{" "}
      </Typography>
      <Typography variant="body2" color="primary" align="center">
        <Link
          style={{ textDecoration: "none", color: "inherit" }}
          color="inherit"
          to="/privacy-policy"
        >
          Privacy & Refund Policy
        </Link>
      </Typography>
      <Typography variant="body2" color="primary" align="center">
        <Link
          style={{ textDecoration: "none", color: "inherit" }}
          color="inherit"
          to="/terms-and-conditions"
        >
          Terms And Conditions
        </Link>
      </Typography>
    </>
  );
}
const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: "url(http://mahafencing.in/assets/img/cadet.jpg)",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    // backgroundPosition: 'center',
    objectFit: "contain",
    maxWidth: "100%",
    maxHeight: "100%",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    width: theme.spacing(10),
    height: theme.spacing(10),
    backgroundImage: "url(/logo512.png)",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  errorhelperText: {
    color: "red",
    fontSize: "1rem",
  },
  helperText: {
    fontSize: "1rem",
    color: "orange",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignIn() {
  const classes = useStyles();
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    aadhar: "",
    password: "",
  });
  const navigate = useNavigate();
  const { setLoading } = useLoading();
  const isLoading = (bool) => {
    setLoading(bool);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const validateForm = () => {
    const errorsCopy = {}; // Initialize a new errors object

    // Validate aadhar
    if (
      formData.aadhar.trim() === "" ||
      formData.aadhar.length < 12 ||
      formData.aadhar.length > 12
    ) {
      errorsCopy.aadhar = "Aadhar is Required";
    }

    // Validate password
    if (
      formData.password.trim() === "" ||
      formData.password.length < 8 ||
      formData.password.length > 8
    ) {
      errorsCopy.password = "DOB is Required (YYYY-MM-DD Format)";
    }

    setErrors(errorsCopy);
  };

  useEffect(
    (e) => {
      validateForm();
      setLoading(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formData]
  );

  useEffect(() => {
    if (localStorage.getItem('access_token') != null && localStorage.getItem('role') === 'candidate')
      navigate("/candidate/profile");
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    if (Object.keys(errors).length !== 0) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "All Fields required...!!",
      });
      setLoading(false);
      return;
    }
    const formDataToSend = new FormData();
    formDataToSend.append("aadhaar", formData.aadhar);
    formDataToSend.append("password", formData.password);

    try {
      const response = await axios.post(apiGeneral.login, formDataToSend, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      setLoading(false);
      Swal.fire({
        icon: "success",
        title: "SUCCESS",
        text: response.data["message"],
      });
      navigate("/candidate/profile");

      if (response.data["success"]) {
        localStorage.setItem(
          "access_token",
          response.data["data"]["access_token"]
        );
        localStorage.setItem(
          "refresh-token",
          response.data["data"]["refresh-token"]
        );
        localStorage.setItem("id", response.data["data"]["id"]);
        localStorage.setItem("name", response.data["data"]["name"]);
        localStorage.setItem("role", response.data["data"]["role"]);
        localStorage.setItem("district_id", response.data["data"]["district_id"]);
      } else {
        isLoading(false);
        Swal.fire({
          icon: "warning",
          title: "Oops...",
          text: response.data["message"],
        });
      }
    } catch (error) {
      setLoading(false);
      console.error("Error during form submission:", error);
      Swal.fire({
        icon: "warning",
        title: "Oops...",
        text: error.response.data['message'],
      });
    }
  };
  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar} src="/logo512.png">
            {/* <LockOutlinedIcon /> */}
          </Avatar>

          <Typography component="h1" variant="h5">
            MAHARASHTRA FENCING ASSOCIATION
          </Typography>
          <br />

          <Typography component="h1" variant="h6">
            <Chip label="Candidate Login" color="primary" />
          </Typography>

          <form className={classes.form} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="aadhar"
              label="Login With Aadhar"
              name="aadhar"
              value={formData.aadhar}
              autoFocus
              onChange={handleInputChange}
            />
            {errors.aadhar && (
              <FormHelperText className={classes.errorhelperText}>
                {errors.aadhar}
              </FormHelperText>
            )}
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              value={formData.password}
              label="Password as DOB"
              type="password"
              id="password"
              onChange={handleInputChange}
            />
            <FormHelperText className={classes.helperText}>
              Note: Your Password as a DOB (YYYYMMDD) Format.
            </FormHelperText>
            {errors.password && (
              <FormHelperText className={classes.errorhelperText}>
                {errors.password}
              </FormHelperText>
            )}
            {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            /> */}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={handleSubmit}
            >
              Sign In
            </Button>
            <Grid container>
              <Grid item xs></Grid>
              <Grid item>
                <Link to="/candidate/registration" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
            <br />
            <Box mt={6}>
              <Copyright />
            </Box>
          </form>
        </div>
      </Grid>
    </Grid>
  );
}
