import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Link } from "react-router-dom";
import { Card, CardContent, Typography } from "@material-ui/core";
import { AddCircle, Edit, Group } from "@material-ui/icons";
import { useLoading } from "../../Components/Layouts/LoadingProvider";
import { networkRequest } from "../../utils/networkRequest";
import { apiGeneral } from "../../utils/urls";
import Swal from "sweetalert2";

const useStyles = makeStyles((theme) => ({
  table: {
    // minWidth: 650,
  },
  card: {
    marginBottom: 16,
    backgroundColor: "#3f51b5",
    color: "white",
  },

  search: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid black",
    borderRadius: "5px",
  },
  margin: {
    margin: theme.spacing(2),
  },
  textAlignRight: {
    textAlign: "right",

  },
  bgPrimary: {
    width: "100%",
    backgroundColor: "#3f51b5",
    color: "white",
  },
  padding: {
    padding: theme.spacing(2),
  },
  textField: {
    width: "60ch",
  },

  textField20ch: {
    width: "20ch",
  },
  truncateText: {
    maxWidth: "200px", // Adjust as needed
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));

const Wrapper = ({ children }) => <div style={{ padding: 16 }}>{children}</div>;

const List = () => {
  const role = localStorage.getItem('role');
  const districtId = localStorage.getItem('district_id');
  const [events, setEvents] = useState([]);
  const { setLoading } = useLoading();
  const isLoading = (bool) => {
    setLoading(bool);
  }
  const getEvents = () => {
    networkRequest(apiGeneral.eventsGet, isLoading, (response) => { setEvents(response['data']) })
  }

  const handleDelete = (data) => {
    Swal.fire({
      title: 'Are you sure want to delete : ' + data.name,
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: false
    }).then((result) => {
      if (result.isConfirmed) {
        networkRequest(`${apiGeneral.deleteEvent}${data.id}`, isLoading, (response) => {
          Swal.fire(
            'Success',
            response['message'],
          )
          if (response['success'])
            getEvents();
        });

      } else if (
        result.dismiss === Swal.DismissReason.cancel
      ) {
        Swal.fire(
          'Cancelled',
          'Your imaginary file is safe :)',
          'error'
        )
      }
    })
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { getEvents() }, [])
  const classes = useStyles();
  return (
    <Wrapper>
      <Card className={classes.card}>
        <CardContent>
          <Grid
            spacing={10}
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item xs={12} sm={6}>
              <Typography variant="h4">
                <Group /> Events List
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} className={classes.textAlignRight}>
              <Link to="/event/add">
                {(role === 'admin' && <Button
                  variant="contained"
                  color="primary"
                  startIcon={<AddCircle />}
                  size="large"
                >
                  Add Events
                </Button>)}
              </Link>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Grid container spacing={1}>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Sr. No</TableCell>
                <TableCell>Event Name</TableCell>
                <TableCell>Event Dates</TableCell>
                <TableCell>Registration Date</TableCell>
                <TableCell>Participation Date</TableCell>
                <TableCell>Event Venue</TableCell>
                <TableCell align="left">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {events.map((row) => (
                <TableRow key={row.id}>
                  <TableCell component="th" scope="row">
                    {row.id}
                  </TableCell>

                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>

                  <TableCell component="th" scope="row">
                    {row.start_date} <br />{row.end_date}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.registration_start_date} <br />{row.registration_end_date}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.participation_age_from} <br />{row.participation_age_to}
                  </TableCell>
                  <TableCell align="left">{row.venue}</TableCell>
                  <TableCell align="left" className={classes.actionButtons}>
                    {role !== 'admin' ? (
                      <Link to={`/event/dashboard/${row.id}/${districtId}`}>
                        <Button
                          variant="contained"
                          color="primary"
                          style={{ marginRight: 4, marginBottom: "5px" }}
                        >
                          <VisibilityIcon />
                        </Button>
                      </Link>
                    ) : (
                      <>
                        <Link to={`/event/details/${row.id}`}>
                          <Button
                            variant="contained"
                            color="primary"
                            style={{ marginRight: 4, marginBottom: "5px" }}
                          >
                            <VisibilityIcon />
                          </Button>
                        </Link>
                        <Link to={"/event/add"} state={row}>
                          <Button
                            variant="contained"
                            style={{ marginRight: 4, marginBottom: "5px" }}
                          >
                            <Edit />
                          </Button>
                        </Link>
                        <Button
                          variant="contained"
                          color="secondary"
                          style={{ marginRight: 4, marginBottom: "5px" }}
                          onClick={() => handleDelete(row)}
                        >
                          <DeleteIcon />
                        </Button>
                      </>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Wrapper >
  );
};

export default List;
