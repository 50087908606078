import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Typography } from "@material-ui/core";
import { AddCircle } from "@material-ui/icons";
import EditIcon from "@material-ui/icons/Edit";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import EmailIcon from "@material-ui/icons/Email";
import SendIcon from "@material-ui/icons/Send";
import clsx from "clsx";
import { Link, useLocation } from "react-router-dom";
import { networkRequest } from "../../utils/networkRequest";
import { apiGeneral } from "../../utils/urls";
import { useLoading } from "../../Components/Layouts/LoadingProvider";
import Swal from "sweetalert2";

const useStyles = makeStyles({
  table: {
    // minWidth: 650,
  },
  card: {
    marginBottom: 16,
    backgroundColor: "#3f51b5",
    color: "white",
  },
  actionButtons: {
    display: "flex",
    gap: 8, // Adjust the spacing between buttons
  },
  search: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid black",
    borderRadius: "5px",
  },
  dropDownField: {
    width: "200px",
    marginRight: "10px",
  },
  textAlignRight: {
    textAlign: "right",
  },
  icon: {
    color: "white",
    backgroundColor: "#1181E6",
  },
});

const Wrapper = ({ children }) => <div style={{ padding: 16 }}>{children}</div>;
export default function UsersList() {
  const location = useLocation();
  const classes = useStyles();
  // eslint-disable-next-line no-unused-vars
  const { setLoading } = useLoading();
  const isLoading = (bool) => {
    setLoading(bool);
  }
  const [userlist, setUserList] = useState([]);

  const fetchUserListData = () => {
    networkRequest(apiGeneral.userlistGet, isLoading, handleResponse);
  };
  const handleResponse = (response) => {
    setUserList(response["data"]);


  };
  useEffect(() => { fetchUserListData() },

    []);

  useEffect(() => {
    if (location.state) {
      setUserList([location.state]);
    }
  }, [location.state]);


  const sendPassword = (id = null) => {
    let endpoint = apiGeneral.sendPassword;
    if (id !== null) {
      endpoint += `/${id}`;
    }

    networkRequest(endpoint, isLoading, (response) => {
      Swal.fire(
        'Success!',
        'Password has been sent to your email',
        'success'
      );
    });
  };


  const handleDelete = (data) => {
    Swal.fire({
      title: 'Are you sure want to delete : ' + data.name,
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: false
    }).then((result) => {
      if (result.isConfirmed) {
        networkRequest(`${apiGeneral.deleteUser}${data.id}`, isLoading, (response) => {
          Swal.fire(
            'Success',
            response['message'],
          )
          if (response['success'])
            fetchUserListData();
        });

      } else if (
        result.dismiss === Swal.DismissReason.cancel
      ) {
        Swal.fire(
          'Cancelled',
          'Your imaginary file is safe :)',
          'error'
        )
      }
    })
  };

  return (
    <Wrapper>
      <Card className={classes.card}>
        <CardContent>
          <Grid
            spacing={10}
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item xs={12} sm={6}>
              <Typography variant="h4">
                <PermIdentityIcon /> Users
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              className={clsx(classes.textAlignRight, classes.padding)}
            >
              <Link to="/users/add-users">
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<AddCircle />}
                  size="large"
                >
                  Add Users
                </Button>
              </Link>
              <Button
                onClick={() => { sendPassword() }}
                variant="contained"
                color="primary"
                style={{ marginLeft: "5px", backgroundColor: "#1181E6" }}
                startIcon={<SendIcon />}
                size="large"
              >
                Send To All
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Grid container spacing={1}>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Sr. No</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Contact</TableCell>
                <TableCell>District</TableCell>
                <TableCell>Password</TableCell>
                <TableCell align="left">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {userlist.map((row) => (
                <TableRow key={row.id} value={row.id}>
                  <TableCell component="th" scope="row">
                    {row.id}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.email}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.contact}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.district}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.password}
                  </TableCell>
                  <TableCell align="left" className={classes.actionButtons}>
                    <Button
                      onClick={() => { sendPassword(row.id) }}
                      variant="contained"
                      // color="secondary"
                      className={classes.icon}
                      style={{ marginRight: 4, marginBottom: "5px" }}
                    >
                      <EmailIcon />
                    </Button>
                    <Link to="/users/add-users"
                      state={row}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ marginRight: 4, marginBottom: "5px" }}
                      >
                        {<EditIcon />}
                      </Button>
                    </Link>
                    <Button
                      variant="contained"
                      color="secondary"
                      style={{ marginRight: 4, marginBottom: "5px" }}
                      onClick={(e) => {
                        handleDelete(row)
                      }}
                    >
                      {<DeleteIcon />}
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Wrapper>
  );
}
