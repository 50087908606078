import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Typography } from "@material-ui/core";
import ArrowBackOutlinedIcon from "@material-ui/icons/ArrowBackOutlined";
import PeopleIcon from '@material-ui/icons/People';
import { Link, useParams } from "react-router-dom";
import { apiGeneral } from "../../utils/urls";
import { networkRequest } from "../../utils/networkRequest";
import { useLoading } from "../../Components/Layouts/LoadingProvider";
import { SaveAlt } from "@material-ui/icons";
import excelExport from "../../Extras/excel-export";

const useStyles = makeStyles({
  table: {
    // minWidth: 650,
  },
  card: {
    marginBottom: 16,
    backgroundColor: "#3f51b5",
    color: "white",
  },
  actionButtons: {
    display: "flex",
    gap: 8, // Adjust the spacing between buttons
  },
  search: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid black",
    borderRadius: "5px",
  },
  dropDownField: {
    width: "200px",
    marginRight: "10px",
  },
  textAlignRight: {
    textAlign: "right",
  },
});

const Wrapper = ({ children }) => <div style={{ padding: 16 }}>{children}</div>;

export default function CoachesAndAuthorize() {
  const [coachesAndAuthorities, setCoachesAndAuthorities] = useState([]);
  const { id } = useParams();
  const classes = useStyles();
  const { setLoading } = useLoading();
  const [coachList, setCoachList] = useState([]);
  const isLoading = (bool) => {
    setLoading(bool);
  }

  const getAllCoaches = () => {
    networkRequest(
      `${apiGeneral.getAllCoaches}/${id}`,
      isLoading,
      (response) => {
        setCoachList(response["data"]);
      },
    );
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { getAllCoaches() }, [])

  const transformData = (data) => {
    return data.map((item, index) => ({
      "Serial No.": index + 1,
      "District": item.district,
      "Boys Coach Name": item.boys_coach_name,
      "Boys Coach Contact": item.boys_coach_contact,
      "Boys Manager Name": item.boys_manager_name,
      "Boys Manager Contact": item.boys_manager_contact,
      "Girls Coach Name": item.girls_coach_name,
      "Girls Coach Contact": item.girls_coach_contact,
      "Girls Manager Name": item.girls_manager_name,
      "Girls Manager Contact": item.girls_manager_contact,
      "Authorised Name": item.authorised_name,
      "Authorised Contact": item.authorised_contact,
      "Association": item.association,
    }));
  };

  const handleExport = () => {
    const transformed = transformData(coachList);
    excelExport(transformed, `coaches_and_managers_${id}`);
  }


  return (
    <Wrapper>
      <Card className={classes.card}>
        <CardContent>
          <Grid
            spacing={10}
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            className={classes.head}
          >
            <Grid item xs={12} sm={6}>
              <Typography variant="h4">
                <PeopleIcon /> Coaches And Managers
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} className={classes.textAlignRight}>
              <Button
                onClick={handleExport}
                variant="contained"
                startIcon={<SaveAlt />}
                size="small"
                style={{ marginRight: "10px" }}
              >
                Export
              </Button>
              <Link to={`/event/details/${id}`}>
                <Button
                  variant="contained"
                  startIcon={<ArrowBackOutlinedIcon />}
                  size="small"
                >
                  Back
                </Button>
              </Link>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Grid container spacing={1}>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Sr. No</TableCell>
                <TableCell>DISTRICT</TableCell>
                <TableCell>BOYS COACH</TableCell>
                <TableCell>BOYS COACH CONTACT</TableCell>
                <TableCell>BOYS MANAGER</TableCell>
                <TableCell>BOYS MANAGER CONTACT</TableCell>
                <TableCell>GIRLS COACH</TableCell>
                <TableCell>GIRLS COACH CONTACT </TableCell>
                <TableCell>GIRLS MANAGER </TableCell>
                <TableCell>GIRLS MANAGER CONTACT </TableCell>
                <TableCell>AUTHORISED NAME</TableCell>
                <TableCell> NAME OF ASSOCIATION</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {coachList.map((row, i) => (
                <TableRow key={row.id}>
                  <TableCell component="th" scope="row">
                    {(i + 1)}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.district}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.boys_coach_name}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.boys_coach_contact}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.boys_manager_name}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.boys_manager_contact}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.girls_coach_name}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.girls_coach_contact}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.girls_manager_name}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.girls_manager_contact}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.authorised_name}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.association}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Wrapper >
  );
}
