import React from "react";
import { useState, useEffect } from "react";

import Button from "@material-ui/core/Button";

import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

import {
  FormControl,
  FormHelperText,
  FormLabel,
  InputLabel,
  Radio,
  RadioGroup,
  Select,
  Card,
  CardContent,
} from "@material-ui/core";

import axios from "axios";
import { apiGeneral } from "../utils/urls";
import Swal from "sweetalert2";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Wrapper from "../Components/Wrapper";

import { networkRequest } from "../utils/networkRequest";
import { useLoading } from "../Components/Layouts/LoadingProvider";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginBottom: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    width: theme.spacing(8),
    height: theme.spacing(8),
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  formControl: {
    minWidth: "100%",
  },
  helperText: {
    fontSize: "1rem",
    color: "orange",
    fontWeight: "bold",
  },
  errorhelperText: {
    color: "red",
    fontSize: "1rem",
  },
  fullScreenDialog: {
    "& .MuiDialog-paperFullScreen": {
      width: "100%",
      height: "100%",
    },
  },
  card: {
    backgroundColor: "#3f51b5",
    color: "white",
  },
  textAlignRight: {
    textAlign: "right",
  },
}));
const EditDetailsForm = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const classes = useStyles();
  const [districts, setDistricts] = useState([]);
  const [formData, setFormData] = useState({
    district: "",
    district_id: "",
    name: "",
    dob: "",
    gender: "",
    email: "",
    mother: "",
    aadhaar: "",
    address: "",
    contact: "",
    uploadPhoto: "",
    uploadDocuments: "",
  });
  const [errors, setErrors] = useState({});
  const [aadharMatchMessage, setAadharMatchMessage] = useState();

  const { loading, setLoading } = useLoading();
  const isLoading = (bool) => {
    setLoading(bool);
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleUpdateDetails = (e) => {
    e.preventDefault();
    if (Object.keys(errors).length !== 0) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "All Fields required...!!",
      });
      return;
    }
    const formDataToSend = new FormData();
    formDataToSend.append("district_id", formData.district_id);
    formDataToSend.append("name", formData.name);
    formDataToSend.append("dob", formData.dob);
    formDataToSend.append("gender", formData.gender);
    formDataToSend.append("email", formData.email);
    formDataToSend.append("mother", formData.mother);
    formDataToSend.append("aadhaar", formData.aadhaar);
    formDataToSend.append("contact", formData.contact);
    formDataToSend.append("address", formData.address);

    networkRequest(
      apiGeneral.editProfileDetails,
      isLoading,
      handleResponseEditDetails,
      "post",
      formDataToSend
    );
  };

  const validateForm = () => {
    const errorsCopy = {}; // Initialize a new errors object

    // Validate district
    if (!formData.district.trim()) {
      errorsCopy.district = "District is required";
    }

    // Validate name
    if (!formData.name.trim()) {
      errorsCopy.name = "Name is required";
    }

    // Validate date of birth
    if (!formData.dob.trim()) {
      errorsCopy.dob = "Date of Birth is required";
    }

    // Validate gender
    if (!formData.gender.trim()) {
      errorsCopy.gender = "Gender is required";
    }

    // Validate email
    if (!formData.email.trim()) {
      errorsCopy.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errorsCopy.email = "Invalid email address";
    }

    // Validate mother's name
    if (!formData.mother.trim()) {
      errorsCopy.mother = "Mother's Name is required";
    }

    // Validate Aadhar Card
    if (!formData.aadhaar.trim()) {
      errorsCopy.aadhaar = "Aadhar Card number is required";
    } else if (!/^\d{12}$/.test(formData.aadhaar)) {
      errorsCopy.aadharCard = "Invalid Aadhar Card number";
    }

    // Validate confirm Aadhar Card
    if (!formData.aadhaar.trim()) {
      errorsCopy.aadhaar = "Confirm Aadhar Card is required";
    } else if (formData.aadhaar !== formData.aadhaar) {
      errorsCopy.confirmAadharCard = "Aadhar Card numbers do not match";
    } else {
      // Aadhar Card numbers match
      console.log("Aadhar Card numbers match");
      // Or display a message to the user
      // setAadharMatchMessage("Aadhar Card numbers match"); // Assuming you have a state variable for the message
    }

    // Validate address
    if (!formData.address.trim()) {
      errorsCopy.address = "Address is required";
    }
    if (!/^\d{10}$/.test(formData.contact.trim())) {
      errorsCopy.contact = "Contact must be exactly 10 digits";
    }

    // Update the state with errors, if any
    setErrors(errorsCopy);
  };

  const handleResponseEditDetails = (response) => {
    console.log(response);
    Swal.fire({
      icon: "success",
      title: "SUCCESS",
      text: response["message"],
    });

    // navigate("/candidate/profile");
  };

  useEffect(() => {
    const fetchDistricts = async () => {
      try {
        const response = await axios.get(apiGeneral.getDistricts);
        setDistricts(response.data["data"]);
        console.log(response.data["data"]);
      } catch (error) {
        console.error("Error fetching districts:", error);
      }
    };

    fetchDistricts();
  }, []);
  useEffect(() => {
    if (location.state !== null) {
      setFormData(location.state);
    }
    console.log(location);
  }, []);
  useEffect(() => {
    validateForm();
    setLoading(false);
  }, [formData]);

  return (
    <Wrapper>
      <Card className={classes.card}>
        <CardContent>
          <Grid
            spacing={1}
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12} sm={10}>
              <Typography variant="h6">Edit Personal Details</Typography>
            </Grid>
            <Grid item xs={12} sm={2}>
              <Link to={"/candidate/profile"}>
                <Button variant="contained">Back</Button>
              </Link>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <form className={classes.form} onSubmit={""}>
        <Grid
          style={{
            padding: "10px",
            margin: "auto",
          }}
          sm={6}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel htmlFor="outlined-district-native-simple">
                  Select District
                </InputLabel>
                <Select
                  native
                  fullWidth
                  value={formData.district_id}
                  onChange={handleInputChange}
                  label="Select District"
                  inputProps={{
                    name: "district_id",
                    id: "outlined-district-native-simple",
                  }}
                >
                  <option aria-label="None" value="" />
                  {districts.map((district) => (
                    <option key={district.id} value={district.id}>
                      {district.district}
                    </option>
                  ))}
                </Select>
              </FormControl>
              {errors.district && (
                <FormHelperText className={classes.errorhelperText}>
                  {errors.district}
                </FormHelperText>
              )}
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                name="name"
                variant="outlined"
                fullWidth
                id="name"
                label="Full Name"
                autoFocus
                onChange={handleInputChange}
                value={formData.name}
              />
              {errors.name && (
                <FormHelperText className={classes.errorhelperText}>
                  {errors.name}
                </FormHelperText>
              )}
              <FormHelperText className={classes.helperText}>
                Note: Name must be as per official documents.
              </FormHelperText>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="dob"
                name="dob"
                variant="outlined"
                label="Birthday"
                type="date"
                fullWidth
                value={formData.dob}
                onChange={handleInputChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              {errors.dob && (
                <FormHelperText className={classes.errorhelperText}>
                  {errors.dob}
                </FormHelperText>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl>
                <FormLabel id="gender-label">Gender</FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="gender-label"
                  name="gender"
                  value={formData.gender}
                  onChange={handleInputChange}
                >
                  <FormControlLabel
                    value="female"
                    control={<Radio />}
                    label="Female"
                  />
                  <FormControlLabel
                    value="male"
                    control={<Radio />}
                    label="Male"
                  />
                </RadioGroup>
                {errors.gender && (
                  <FormHelperText className={classes.errorhelperText}>
                    {errors.gender}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
              />
              {errors.email && (
                <FormHelperText className={classes.errorhelperText}>
                  {errors.email}
                </FormHelperText>
              )}
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                name="mother"
                type="text"
                id="mother"
                value={formData.mother}
                onChange={handleInputChange}
              />
              {errors.motherName && (
                <FormHelperText className={classes.errorhelperText}>
                  {errors.motherName}
                </FormHelperText>
              )}
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                fullWidth
                id="aadhaar"
                name="aadhaar"
                value={formData.aadhaar}
                onChange={handleInputChange}
              />
              {errors.aadharCard && (
                <FormHelperText className={classes.errorhelperText}>
                  {errors.aadharCard}
                </FormHelperText>
              )}
              {aadharMatchMessage && (
                <FormHelperText>{aadharMatchMessage}</FormHelperText>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                fullWidth
                id="confirmAadharCard"
                name="confirmAadharCard"
                value={formData.aadhaar}
                onChange={handleInputChange}
              />
              {errors.confirmAadharCard && (
                <FormHelperText className={classes.errorhelperText}>
                  {errors.confirmAadharCard}
                </FormHelperText>
              )}
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                id="contact"
                name="contact"
                value={formData.contact}
                onChange={handleInputChange}
              />
              {errors.contact && (
                <FormHelperText className={classes.errorhelperText}>
                  {errors.contact}
                </FormHelperText>
              )}
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="address"
                name="address"
                placeholder="Enter your address"
                multiline
                fullWidth
                value={formData.address}
                variant="outlined"
                onChange={handleInputChange}
              />
              {errors.address && (
                <FormHelperText className={classes.errorhelperText}>
                  {errors.address}
                </FormHelperText>
              )}
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handleUpdateDetails}
          >
            Update Details
          </Button>
        </Grid>
      </form>
    </Wrapper>
  );
};

export default EditDetailsForm;
