import React, { useEffect, useState } from "react";
import Wrapper from "../../../Components/Wrapper";
import {
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { Cancel, Delete, Group, Save } from "@material-ui/icons";
import { useNavigate, useParams } from "react-router-dom";
import EventNoteIcon from "@material-ui/icons/EventNote";
import clsx from "clsx";
import SearchIcon from "@material-ui/icons/Search";
import { useLoading } from "../../../Components/Layouts/LoadingProvider";
import { networkRequest } from "../../../utils/networkRequest";
import { apiGeneral } from "../../../utils/urls";
import Swal from "sweetalert2";
import ClearIcon from "@material-ui/icons/Clear";

import CheckIcon from "@material-ui/icons/Check";
const useStyles = makeStyles((theme) => ({
  card: {
    marginBottom: 16,
    display: "flex",
    gap: 8,
  },
  male_heading: {
    marginTop: 10,
    marginBottom: 16,
    // backgroundColor: "#3f51b5",
    backgroundColor: "#F7F7F7",
    color: "black",
  },
  search: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid black",
    borderRadius: "5px",
  },
  margin: {
    margin: theme.spacing(2),
  },
  textAlignRight: {
    textAlign: "right",
  },
  bgPrimary: {
    width: "100%",
    backgroundColor: "#3f51b5",
    color: "white",
  },
  padding: {
    padding: theme.spacing(2),
  },
  textField: {
    width: "40ch",
    height: "4ch",
  },
  errorhelperText: {
    color: "red",
    fontSize: "1rem",
  },
}));
const selectClass = [
  {
    value: "",
    label: "",
  },
  {
    value: "OTHER",
    label: "other",
  },
  {
    value: "FIRST",
    label: "FIRST",
  },
  {
    value: "SECOND",
    label: "SECOND",
  },
  {
    value: "THIRD",
    label: "THIRD",
  },
  {
    value: "FORTH",
    label: "FORTH",
  },
  {
    value: "FIFTH",
    label: "FIFTH",
  },
  {
    value: "SIXTH",
    label: "SIXTH",
  },
  {
    value: "SEVENTH",
    label: "SEVENTH",
  },
  {
    value: "EIGHTH",
    label: "EIGHTH",
  },
  {
    value: "NINTH",
    label: "NINTH",
  },
  {
    value: "TENTH",
    label: "TENTH",
  },
  {
    value: "ELEVENTH",
    label: "ELEVENTH",
  },
  {
    value: "TWELFTH",
    label: "TWELFTH",
  },
  {
    value: "Under Graduate",
    label: "Under Graduate",
  },
  {
    value: "Post Graduate",
    label: "Post Graduate",
  },
];

const EntryForm = () => {
  const classes = useStyles();
  const { id, type, district_id } = useParams();
  const [candidateId, setCandidateId] = useState("");
  const { setLoading } = useLoading();
  const [candidatesList, setCandidateList] = useState([]);
  const [errors, setErrors] = useState({});
  const [candidateLimit, setCandidateLimit] = useState({
    'epee': 0,
    'foil': 0,
    'sabre': 0
  })
  const navigate = useNavigate();

  const isLoading = (bool) => {
    setLoading(bool);
  };
  const getCandidateList = () => {
    networkRequest(
      apiGeneral.getCandidateList,
      isLoading,
      (response) => {
        setCandidateList(response["data"]['candidate']);
        const limit = response['data']['limit'];
        setCandidateLimit((data) => ({ ...data, epee: limit['epee'] ?? 0, foil: limit['foil'] ?? 0, sabre: limit['sabre'] ?? 0 }))
      }, 'get', { event_id: id, district_id: district_id, gender: type === "Boys" ? "male" : "female" }
    );
  };
  useEffect(() => {
    setFormData((data) => ({ ...data, event_id: id }));
    setFormData((data) => ({ ...data, district_id: district_id }));
    setFormData((data) => ({
      ...data,
      gender: type === "Boys" ? "male" : "female",
    }));
    isLoading(false);
    getCandidateList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [formData, setFormData] = useState({
    candidate_id: "",
    event_id: "",
    district_id: "",
    gender: "",
    name: "",
    dob: "",
    contact: "",
    address: "",
    school: "",
    class: "",
    fai: "",
    epee: false,
    foil: false,
    sabre: false,
  });

  const handleIdInput = (e) => {
    const value = e.target.value.toUpperCase();
    if (value.length >= 3) {
      const regex = /^MF\d*$/;
      if (regex.test(value) || value === "") {
        setFormData((data) => ({ ...data, candidate_id: value.slice(2) }));
        setCandidateId(value);
      } else {
        alert('ID must start with "MF" followed by numbers (e.g., MF564)');
      }
    }
  };

  const getCandidateEligibility = () => {
    // Reset form data in a single operation
    setFormData((prevData) => ({
      ...prevData,
      name: "",
      dob: "",
      contact: "",
      address: "",
      school: "",
      class: "",
      fai: "",
    }));

    // Prepare FormData for the network request
    const data = new FormData();
    Object.entries(formData).forEach(([key, value]) => {
      data.append(key, value);
    });

    // Perform the network request
    networkRequest(
      apiGeneral.getCandidateEligibility,
      isLoading,
      (response) => {
        if (response.success) {
          // Update form data based on the response
          setFormData((prevData) => ({
            ...prevData,
            name: response.data.name,
            dob: response.data.dob,
            contact: response.data.contact,
            address: response.data.address,
            fai: response.data.fai,
          }));
        }
      },
      "post",
      data
    );
  };

  useEffect(() => {
    if (candidateId === "") return;
    let timeoutId;
    // Function to execute after delay
    const delayedCallback = () => {
      getCandidateEligibility();
    };

    // Clear previous timeout if exists
    clearTimeout(timeoutId);

    // Set new timeout
    timeoutId = setTimeout(delayedCallback, 2000); // Delay of 500 milliseconds

    // Cleanup function to clear timeout if component unmounts or dependencies change
    return () => clearTimeout(timeoutId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [candidateId]);

  const handleChange = (event) => {
    setFormData((data) => ({ ...data, class: event.target.value }));
  };

  const handleInput = (event) => {
    const { name, value } = event.target;
    setFormData((data) => ({ ...data, [name]: value }));
  };

  const handleChecked = (event) => {
    const { name, checked } = event.target;

    // Check if the checkbox is being checked
    if (checked) {
      // Count the currently selected events
      const currentSelectionCount = Object.values(formData).filter(
        (value) => value === true && ["epee", "foil", "sabre"].includes(name)
      ).length;

      // If checking this box would exceed the limit of 2 selections, show an alert and return early
      console.log(currentSelectionCount)
      if (currentSelectionCount >= 2) {
        Swal.fire("opps", "You have already chosen 2 events.");
        return; // Prevent further execution
      }
    }

    // Update the form data
    setFormData((data) => ({ ...data, [name]: checked }));
  };

  const validateForm = () => {
    const errorsCopy = {}; // Initialize a new errors object

    // Validate userTypes
    if (formData.class === "") {
      errorsCopy.class = "Class is required";
    }
    if (!formData.school) {
      errorsCopy.school = "School Name is Required";
    }

    // Validate email

    setErrors(errorsCopy);
  };

  const handleSubmit = () => {
    console.log(errors)
    if (Object.keys(errors).length !== 0) {
      return;
    }
    // Check if the name field is empty
    if (formData.name === "") {
      Swal.fire("Oops", "Please select a candidate");
      return;
    }

    // Check if at least one of epee, foil, or sabre is selected
    const weaponsSelected = [formData.epee, formData.foil, formData.sabre].some(
      (value) => value !== 0
    );
    if (!weaponsSelected) {
      Swal.fire(
        "Oops",
        "Please select at least one weapon (Epee, Foil, Sabre)"
      );
      return;
    }

    // Proceed with form submission
    const data = new FormData();
    data.append("save", 1);
    Object.entries(formData).forEach(([key, value]) => {
      data.append(key, (value === null) ? 0 : value.toString()); // Ensure value is converted to string if necessary
    });

    networkRequest(
      apiGeneral.getCandidateEligibility,
      isLoading,
      (response) => {
        Swal.fire("success", response["message"]);
        getCandidateList();
        setCandidateId("");
        setFormData((data) => ({
          ...data,
          candidate_id: "",
          name: "",
          dob: "",
          contact: "",
          address: "",
          school: "",
          class: "",
          fai: "",
          epee: 0,
          foil: 0,
          sabre: 0,
        }));
      },
      "post",
      data
    );
  };
  useEffect(() => {
    validateForm();
  }, [formData]);

  const handleCandidateDelete = (id) => {
    Swal.fire({
      title: "Are you sure want to delete : ",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: false,
    }).then((result) => {
      if (result.isConfirmed) {
        networkRequest(
          `${apiGeneral.getEventCandidateDelete}/${id}`,
          isLoading,
          (response) => {
            Swal.fire("Success", response["message"]);
            if (response["success"]) getCandidateList();
          }
        );
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your imaginary file is safe :)", "error");
      }
    });
  };

  return (
    <Wrapper>
      <Card className={classes.card}>
        <Grid item xs={12} sm={12}>
          <Typography
            className={clsx(classes.padding, classes.bgPrimary)}
            variant="h4"
          >
            <EventNoteIcon /> Entry Form For {type}
          </Typography>
          <Card>
            <Grid item xs={12} sm={12}>
              <FormControl className={clsx(classes.margin, classes.textField)}>
                <TextField
                  name="id"
                  variant="outlined"
                  id="id"
                  type="text"
                  label="PLAYER ID"
                  autoFocus
                  size="small"
                  placeholder="MF256"
                  onChange={handleIdInput}
                  inputProps={{ style: { textTransform: "uppercase" } }}
                />
              </FormControl>
            </Grid>
            <FormControl className={clsx(classes.margin, classes.textField)}>
              <TextField
                name="venue"
                variant="outlined"
                id="venue"
                type="text"
                disabled
                label="NAME"
                size="small"
                value={formData.name}
                autoFocus
              />
            </FormControl>
            <FormControl className={clsx(classes.margin, classes.textField)}>
              <TextField
                id="date"
                label="DATE OF BIRTH"
                type="date"
                variant="outlined"
                disabled
                name="dob"
                value={formData.dob}
                size="small"
                defaultValue="dd-mm-year"
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </FormControl>
            <FormControl className={clsx(classes.margin, classes.textField)}>
              <TextField
                name="contact"
                variant="outlined"
                id="contact"
                disabled
                type="number"
                value={formData.contact}
                size="small"
                label="Contact"
                autoFocus
              />
            </FormControl>
            <FormControl className={clsx(classes.margin, classes.textField)}>
              <TextField
                id="outlined-select-DivisionClasses-native"
                select
                label="Select Class"
                value={formData.class}
                onChange={handleChange}
                size="small"
                variant="outlined"
              >
                {selectClass.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              {errors.class && (
                <FormHelperText className={classes.errorhelperText}>
                  {errors.class}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl className={clsx(classes.margin, classes.textField)}>
              <TextField
                name="school"
                variant="outlined"
                id="school"
                value={formData.school}
                type="text"
                onChange={handleInput}
                label="School(if Not Type Other)"
                size="small"
                autoFocus
              />
              {errors.school && (
                <FormHelperText className={classes.errorhelperText}>
                  {errors.school}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl className={clsx(classes.margin, classes.textField)}>
              <TextField
                name="fai"
                variant="outlined"
                id="fai"
                value={formData.fai}
                type="text"
                onChange={handleInput}
                label="FAI Reg. No./Order.No.(Optional)"
                size="small"
                autoFocus
              />
            </FormControl>
            <Grid sm={12}>
              <Typography
                style={{
                  marginTop: "25px",
                  marginLeft: "15px",
                  fontSize: "1rem",
                }}
                variant="h6"
              >
                Select Event group (Select Atleast One Event)
              </Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={candidateLimit.foil === "4"}
                    checked={formData.foil}
                    onChange={handleChecked}
                    name="foil"
                    color="primary"
                    style={{ marginLeft: "1rem" }}
                  />
                }
                label="FOIL"
              />

              <FormControlLabel
                control={
                  <Checkbox
                    disabled={candidateLimit.epee === "4"}
                    checked={formData.epee}
                    onChange={handleChecked}
                    name="epee"
                    color="primary"
                    style={{ marginLeft: "1rem" }}
                  />
                }
                label="EPEE"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={candidateLimit.sabre === "4"}
                    checked={formData.sabre}
                    onChange={handleChecked}
                    name="sabre"
                    color="primary"
                    style={{ marginLeft: "1rem" }}
                  />
                }
                label="SABRE"
              />
            </Grid>
            <Grid sm={12} style={{ marginLeft: "1rem" }}>
              <Typography variant="h6" style={{ fontSize: "1rem" }}>
                Note :
              </Typography>
              <Grid sm={12}>
                <Typography variant="p">
                  • I Have Checked The Information Of All Above Players
                  Regarding The Date Of Birth And Other Details.
                </Typography>
              </Grid>
              <Grid sm={12}>
                <Typography variant="p">
                  • This Is To Certify That If Any Discrepancy Is Found
                  Regarding The Provided Information, Players Themselves And
                  District Association Will Be Responsible For It.
                </Typography>
              </Grid>
            </Grid>
            <Card>
              <Button
                variant="contained"
                color="Primary"
                size="large"
                onClick={handleSubmit}
                className={clsx(classes.margin)}
                startIcon={<Save />}
                style={{ backgroundColor: "#17A2B8" }}
              >
                Save
              </Button>
              <Button
                onClick={() => {
                  navigate(-1)
                }}
                variant="contained"
                color="Default"
                size="large"
                className={clsx(classes.margin, classes.button)}
                startIcon={<Cancel />}
              >
                Cancel
              </Button>
            </Card>
          </Card>
          <Card className={classes.male_heading}>
            <CardContent>
              <Grid
                spacing={1}
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item xs={12} sm={9}>
                  <Typography variant="h5">
                    <Group /> Male Candidates
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Grid
            spacing={1}
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid
              item
              xs={12}
              sm={3}
              style={{
                marginBottom: "15px",
                marginLeft: "10px",
              }}
            >
              <TextField
                variant="outlined"
                label="Search  Candidate"
                size="small"
                InputProps={{
                  endAdornment: (
                    <InputAdornment>
                      <IconButton>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Sr. No</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>DOB</TableCell>
                    <TableCell>Contact</TableCell>
                    <TableCell>Address</TableCell>
                    <TableCell>School</TableCell>
                    <TableCell>Class</TableCell>
                    <TableCell>FAI ID</TableCell>
                    <TableCell>Foil</TableCell>
                    <TableCell>Epee</TableCell>
                    <TableCell>Sabre</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {candidatesList.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell component="th" scope="row">
                        {row.candidate_id}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.name}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.dob}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.contact}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.address}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.school_college}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.class}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.fai}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.foil === "1" ? (
                          <>
                            <CheckIcon />
                          </>
                        ) : (
                          <ClearIcon />
                        )}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.epee === "1" ? (
                          <>
                            <CheckIcon />
                          </>
                        ) : (
                          <ClearIcon />
                        )}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.sabre === "1" ? (
                          <>
                            <CheckIcon />
                          </>
                        ) : (
                          <ClearIcon />
                        )}
                      </TableCell>
                      <TableCell align="left" className={classes.actionButtons}>
                        <Button
                          onClick={(e) => {
                            handleCandidateDelete(row.eventCandidateId);
                          }}
                          variant="contained"
                          color="secondary"
                          style={{ marginRight: 4, marginBottom: "5px" }}
                        >
                          {<Delete />}
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Card>
    </Wrapper>
  );
};
export default EntryForm;
