import React, { useState, useCallback, useEffect } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
// import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import {
  FormControl,
  FormHelperText,
  FormLabel,
  InputLabel,
  Radio,
  RadioGroup,
  Select,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slider,
  Chip,
} from "@material-ui/core";
import Cropper from "react-easy-crop";
import axios from "axios";
import { apiGeneral } from "../utils/urls";
import Swal from "sweetalert2";
import { Link, useNavigate } from "react-router-dom";
import { useLoading } from "../Components/Layouts/LoadingProvider";
import { networkRequest } from "../utils/networkRequest";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginBottom: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    width: theme.spacing(8),
    height: theme.spacing(8),
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  formControl: {
    minWidth: "100%",
  },
  helperText: {
    fontSize: "1rem",
    color: "orange",
    fontWeight: "bold",
  },
  errorhelperText: {
    color: "red",
    fontSize: "1rem",
  },
  fullScreenDialog: {
    "& .MuiDialog-paperFullScreen": {
      width: "100%",
      height: "100%",
    },
  },
}));

export default function Registration() {
  const classes = useStyles();
  const [formData, setFormData] = useState({
    district: "",
    name: "",
    dob: "",
    gender: "",
    email: "",
    motherName: "",
    aadharCard: "",
    confirmAadharCard: "",
    address: "",
    contact: "",
    uploadPhoto: "",
    uploadDocuments: "",
  });
  const [errors, setErrors] = useState({
    uploadPhoto: "",
    uploadDocuments: "",
  });
  const [aadharMatchMessage, setAadharMatchMessage] = useState();
  const [districts, setDistricts] = useState([]);
  const [cropDialogOpen, setCropDialogOpen] = useState(false);
  const [imageSrc, setImageSrc] = useState("");
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [isRegistrationOpen, setRegistrationOpen] = useState(false);
  const navigate = useNavigate();
  const { setLoading } = useLoading();

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const isLoading = (b) => {
    setLoading(b)
  }

  useEffect(() => {
    networkRequest(apiGeneral.registrationPortalStatusCandidate, isLoading, (data) => {
      setRegistrationOpen((data['data']['registration'] === "false") ? false : true)
    })
  }, []);

  const handleFileChange = (event) => {
    setFormData((data) => ({ ...data, uploadPhoto: "" }));
    console.log(formData.uploadPhoto);
    const { files } = event.target;
    const file = files[0];

    if (!file) {
      // No file selected, handle this case
      setCroppedImage(null);
      return;
    }

    const reader = new FileReader();
    reader.onload = () => {
      setImageSrc(reader.result);
      setCropDialogOpen(true);
    };
    reader.readAsDataURL(file);
  };
  const handleDocumentInputChange = (event) => {
    const { name, files } = event.target;
    const file = files[0];

    setFormData((prevData) => ({ ...prevData, [name]: file }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleCropSave = async () => {
    if (croppedAreaPixels) {
      const croppedImageURL = await getCroppedImg(imageSrc, croppedAreaPixels);
      setCroppedImage(croppedImageURL);
      setFormData((prevData) => ({
        ...prevData,
        uploadPhoto: croppedImageURL,
      }));

      setErrors((prevErrors) => ({ ...prevErrors, uploadPhoto: "" }));
      setCropDialogOpen(false);
    }
  };

  const handleCancelCropImage = () => {
    setFormData((prevData) => ({ ...prevData, uploadPhoto: "" }));
    console.log(formData.uploadPhoto);
    setCroppedImage(null);
    setCropDialogOpen(false);

    const inputFileRef = document.getElementById("uploadPhoto");
    if (inputFileRef) {
      inputFileRef.value = "";
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    if (Object.keys(errors).length !== 0) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "All Fields required...!!",
      });
      setLoading(false);
      return;
    }

    const formDataToSend = new FormData();
    formDataToSend.append("district_id", formData.district);
    formDataToSend.append("name", formData.name);
    formDataToSend.append("dob", formData.dob);
    formDataToSend.append("gender", formData.gender);
    formDataToSend.append("email", formData.email);
    formDataToSend.append("mother", formData.motherName);
    formDataToSend.append("aadhaar", formData.aadharCard);
    formDataToSend.append("contact", formData.contact);
    formDataToSend.append("address", formData.address);

    // Append the base64 image string directly if available
    if (formData.uploadPhoto) {
      // Strip the base64 header part
      const base64Image = formData.uploadPhoto.replace(
        /^data:image\/jpeg;base64,/,
        ""
      );
      formDataToSend.append("photo", base64Image);
    }

    if (formData.uploadDocuments) {
      formDataToSend.append("document", formData.uploadDocuments);
    }

    // Log formDataToSend entries
    for (let [key, value] of formDataToSend.entries()) {
      console.log(`${key}: ${value}`);
    }
    try {
      const response = await axios.post(apiGeneral.register, formDataToSend, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log("Response:", response.data);

      Swal.fire({
        icon: "success",
        title: "SUCCESS",
        text: response.data["message"],
      });
      if (response.data.success) {
        const responseData = response.data.data;
        localStorage.setItem("access_token", responseData.access_token);
        localStorage.setItem("tmp_id", responseData.id);
        localStorage.setItem("name", responseData.name);
        localStorage.setItem("role", responseData.role);
        localStorage.setItem("refresh_token", responseData.refresh_token);
        return navigate("/candidate/profile");
      } else {
        Swal.fire({
          icon: "warning",
          title: "Oops...",
          text: JSON.stringify(response.data["message"]),
        });
      }
    } catch (error) {
      console.error("Error during form submission:", error);
      alert("There was an error during registration. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const validateForm = () => {
    const errorsCopy = {}; // Initialize a new errors object

    // Validate district
    if (!formData.district.trim()) {
      errorsCopy.district = "District is required";
    }

    // Validate name
    if (!formData.name.trim()) {
      errorsCopy.name = "Name is required";
    }

    // Validate date of birth
    if (!formData.dob.trim()) {
      errorsCopy.dob = "Date of Birth is required";
    }

    // Validate gender
    if (!formData.gender.trim()) {
      errorsCopy.gender = "Gender is required";
    }

    // Validate email
    if (!formData.email.trim()) {
      errorsCopy.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errorsCopy.email = "Invalid email address";
    }

    // Validate mother's name
    if (!formData.motherName.trim()) {
      errorsCopy.motherName = "Mother's Name is required";
    }

    // Validate Aadhar Card
    if (!formData.aadharCard.trim()) {
      errorsCopy.aadharCard = "Aadhar Card number is required";
    } else if (!/^\d{12}$/.test(formData.aadharCard)) {
      errorsCopy.aadharCard = "Invalid Aadhar Card number";
    }

    // Validate confirm Aadhar Card
    if (!formData.confirmAadharCard.trim()) {
      errorsCopy.confirmAadharCard = "Confirm Aadhar Card is required";
    } else if (formData.confirmAadharCard !== formData.aadharCard) {
      errorsCopy.confirmAadharCard = "Aadhar Card numbers do not match";
    } else {
      // Aadhar Card numbers match
      console.log("Aadhar Card numbers match");
      // Or display a message to the user
      // setAadharMatchMessage("Aadhar Card numbers match"); // Assuming you have a state variable for the message
    }

    // Validate address
    if (!formData.address.trim()) {
      errorsCopy.address = "Address is required";
    }
    if (!/^\d{10}$/.test(formData.contact.trim())) {
      errorsCopy.contact = "Contact must be exactly 10 digits";
    }

    // Validate uploadPhoto
    if (!formData.uploadPhoto) {
      errorsCopy.uploadPhoto = "Photo is required";
    }

    // Validate uploadDocuments
    if (!formData.uploadDocuments) {
      errorsCopy.uploadDocuments = "Document is required";
    }

    // Update the state with errors, if any
    setErrors(errorsCopy);
  };

  useEffect(
    (e) => {
      validateForm();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formData]
  );

  useEffect(() => {
    const fetchDistricts = async () => {
      setLoading(true);
      try {
        const response = await axios.get(apiGeneral.getDistricts);
        setDistricts(response.data["data"]);
        console.log(response.data["data"]);
      } catch (error) {
        console.error("Error fetching districts:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchDistricts();
  }, []);

  const getCroppedImg = async (imageSrc, pixelCrop) => {
    const image = await createImage(imageSrc);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;

    ctx.drawImage(
      image,
      pixelCrop.x,
      pixelCrop.y,
      pixelCrop.width,
      pixelCrop.height,
      0,
      0,
      pixelCrop.width,
      pixelCrop.height
    );

    // return new Promise((resolve) => {
    //   canvas.toBlob((blob) => {
    //     resolve(URL.createObjectURL(blob));
    //   }, "image/jpeg");
    // });
    return canvas.toDataURL("image/jpeg");
  };

  const createImage = (url) => {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener("load", () => resolve(image));
      image.addEventListener("error", (error) => reject(error));
      image.setAttribute("crossOrigin", "anonymous");
      image.src = url;
    });
  };

  return (isRegistrationOpen === false) ? (
    <Container component="main" maxWidth="sm">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar} src="/logo512.png"></Avatar>
        <Typography component="h1" variant="h5">
          Registration
        </Typography>
        <br />
        <Typography component="h1" variant="h6">
          <Chip label="Candidate Registration" color="primary" />
        </Typography>
        <br/>
        <Typography component="h1" variant="h5">Registrations Are Temporary Closed.</Typography>
        <Typography>If you already Have Account your can <Link to={'/candidate'}>login here</Link></Typography>
      </div>
    </Container>
  ) :

    (
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar} src="/logo512.png"></Avatar>
          <Typography component="h1" variant="h5">
            Registration
          </Typography>
          <br />
          <Typography component="h1" variant="h6">
            <Chip label="Candidate Registration" color="primary" />
          </Typography>
          <form className={classes.form} onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel htmlFor="outlined-district-native-simple">
                    Select District
                  </InputLabel>
                  <Select
                    native
                    fullWidth
                    value={formData.district}
                    onChange={handleInputChange}
                    label="Select District"
                    inputProps={{
                      name: "district",
                      id: "outlined-district-native-simple",
                    }}
                  >
                    <option aria-label="None" value="" />
                    {districts.map((district) => (
                      <option key={district.id} value={district.id}>
                        {district.district}
                      </option>
                    ))}
                  </Select>
                </FormControl>
                {errors.district && (
                  <FormHelperText className={classes.errorhelperText}>
                    {errors.district}
                  </FormHelperText>
                )}
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  name="name"
                  variant="outlined"
                  fullWidth
                  id="name"
                  label="Full Name"
                  autoFocus
                  onChange={handleInputChange}
                />
                {errors.name && (
                  <FormHelperText className={classes.errorhelperText}>
                    {errors.name}
                  </FormHelperText>
                )}
                <FormHelperText className={classes.helperText}>
                  Note: Name must be as per official documents.
                </FormHelperText>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="dob"
                  name="dob"
                  variant="outlined"
                  label="Birthday"
                  type="date"
                  fullWidth
                  onChange={handleInputChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                {errors.dob && (
                  <FormHelperText className={classes.errorhelperText}>
                    {errors.dob}
                  </FormHelperText>
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl>
                  <FormLabel id="gender-label">Gender</FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="gender-label"
                    name="gender"
                    value={formData.gender}
                    onChange={handleInputChange}
                  >
                    <FormControlLabel
                      value="female"
                      control={<Radio />}
                      label="Female"
                    />
                    <FormControlLabel
                      value="male"
                      control={<Radio />}
                      label="Male"
                    />
                  </RadioGroup>
                  {errors.gender && (
                    <FormHelperText className={classes.errorhelperText}>
                      {errors.gender}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  onChange={handleInputChange}
                />
                {errors.email && (
                  <FormHelperText className={classes.errorhelperText}>
                    {errors.email}
                  </FormHelperText>
                )}
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  name="motherName"
                  label="Mother's Name"
                  type="text"
                  id="motherName"
                  onChange={handleInputChange}
                />
                {errors.motherName && (
                  <FormHelperText className={classes.errorhelperText}>
                    {errors.motherName}
                  </FormHelperText>
                )}
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  type="password"
                  variant="outlined"
                  fullWidth
                  id="aadharCard"
                  label="Aadhar Card"
                  name="aadharCard"
                  onChange={handleInputChange}
                />
                {errors.aadharCard && (
                  <FormHelperText className={classes.errorhelperText}>
                    {errors.aadharCard}
                  </FormHelperText>
                )}
                {aadharMatchMessage && (
                  <FormHelperText>{aadharMatchMessage}</FormHelperText>
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  fullWidth
                  id="confirmAadharCard"
                  label="Confirm Aadhar Card"
                  name="confirmAadharCard"
                  onChange={handleInputChange}
                />
                {errors.confirmAadharCard && (
                  <FormHelperText className={classes.errorhelperText}>
                    {errors.confirmAadharCard}
                  </FormHelperText>
                )}
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  id="contact"
                  label="Contact"
                  name="contact"
                  onChange={handleInputChange}
                />
                {errors.contact && (
                  <FormHelperText className={classes.errorhelperText}>
                    {errors.contact}
                  </FormHelperText>
                )}
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="address"
                  name="address"
                  label="Address"
                  placeholder="Enter your address"
                  multiline
                  fullWidth
                  variant="outlined"
                  onChange={handleInputChange}
                />
                {errors.address && (
                  <FormHelperText className={classes.errorhelperText}>
                    {errors.address}
                  </FormHelperText>
                )}
              </Grid>

              <Grid item xs={12}>
                <FormLabel>Upload Photo :</FormLabel>
                <TextField
                  variant="outlined"
                  fullWidth
                  type="file"
                  id="uploadPhoto"
                  name="uploadPhoto"
                  onChange={handleFileChange}
                  inputProps={{
                    accept: "image/*",
                  }}
                />
                {errors.uploadPhoto && (
                  <FormHelperText className={classes.errorhelperText}>
                    {errors.uploadPhoto}
                  </FormHelperText>
                )}
                <FormHelperText className={classes.helperText}>
                  Note: Photo must be in Image format.
                </FormHelperText>
              </Grid>
              {croppedImage && (
                <>
                  <Grid item xs={10}>
                    <img
                      src={croppedImage}
                      alt="Cropped"
                      style={{ maxWidth: "100%" }}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <Button variant="contained" onClick={handleCancelCropImage}>
                      X
                    </Button>
                  </Grid>
                </>
              )}
              <Grid item xs={12}>
                <FormLabel>Upload Date of Birth proof :</FormLabel>
                <TextField
                  variant="outlined"
                  fullWidth
                  type="file"
                  id="uploadDocuments"
                  name="uploadDocuments"
                  onChange={handleDocumentInputChange}
                />
                {errors.uploadDocuments && (
                  <FormHelperText className={classes.errorhelperText}>
                    {errors.uploadDocuments}
                  </FormHelperText>
                )}

                <FormHelperText className={classes.helperText}>
                  Note: Aadhar/Passport must be both side photo/PDF.
                </FormHelperText>

                <FormHelperText color="primary" style={{ textAlign: 'center', color: 'red' }}>
                  Registration Charges will be 300 + 3% service charge
                </FormHelperText>
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Sign Up
            </Button>
            <Grid style={{ display: "flex", justifyContent: "center" }}>
              <Grid item>
                <Link to="/candidate/login" variant="body2">
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>
            <Typography variant="body2" color="primary" align="center">
              <Link
                style={{ textDecoration: "none", color: "inherit" }}
                color="inherit"
                to="/privacy-policy"
              >
                Privacy & Refund Policy
              </Link>
            </Typography>
          </form>
        </div>
        <Grid>
          <Dialog
            open={cropDialogOpen}
            onClose={() => setCropDialogOpen(false)}
            aria-labelledby="form-dialog-title"
            className={classes.fullScreenDialog}
            fullWidth
            maxWidth="sm"
          >
            <DialogTitle id="form-dialog-title">Crop Image</DialogTitle>
            <DialogContent>
              <div
                style={{
                  position: "relative",
                  width: "100%",
                  height: 400,
                  background: "#333",
                }}
              >
                <Cropper
                  image={imageSrc}
                  crop={crop}
                  zoom={zoom}
                  aspect={1}
                  onCropChange={setCrop}
                  onZoomChange={setZoom}
                  onCropComplete={onCropComplete}
                />
              </div>
              <div style={{ marginTop: 16 }}>
                <Typography gutterBottom>Zoom</Typography>
                <Slider
                  value={zoom}
                  min={1}
                  max={3}
                  step={0.1}
                  aria-labelledby="Zoom"
                  onChange={(e, zoom) => setZoom(zoom)}
                />
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setCropDialogOpen(false)} color="primary">
                Cancel
              </Button>
              <Button onClick={handleCropSave} color="primary">
                Save
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
      </Container>
    );
}
