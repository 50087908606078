import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import EditIcon from '@material-ui/icons/Edit';
import Card from "@material-ui/core/Card";
import { CardContent, Typography } from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import ArrowBackOutlinedIcon from "@material-ui/icons/ArrowBackOutlined";
import { Link, useParams } from "react-router-dom";
import ListIcon from '@material-ui/icons/List';
import { networkRequest } from "../../../utils/networkRequest";
import { apiGeneral } from "../../../utils/urls";
import { useLoading } from "../../../Components/Layouts/LoadingProvider";
import { downloadPDF } from "../../../utils/download-pdf";
const useStyles = makeStyles((theme) => ({
    card: {
        marginBottom: 16,
        backgroundColor: "#3f51b5",
        color: "white",
    },
    search: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        border: "1px solid black",
        borderRadius: "5px",
    },
    margin: {
        margin: theme.spacing(2),
    },
    textAlignRight: {
        textAlign: "right",
    },
    bgPrimary: {
        width: "100%",
        backgroundColor: "#3f51b5",
        color: "white",
    },
    padding: {
        padding: theme.spacing(2),
    },

}));

const Wrapper = ({ children }) => <div style={{ padding: 16 }}>{children}</div>;

export default function DistrictList() {
    const classes = useStyles();
    const [district, setDistrict] = useState([]);
    const { id } = useParams()
    // eslint-disable-next-line no-unused-vars
    const { loading, setLoading } = useLoading();
    const isLoading = (bool) => {
        setLoading(bool);
    };
    const fetchDistrictList = () => {
        networkRequest(apiGeneral.getDistricts, isLoading, handleResponse);
    };
    const handleResponse = (response) => {
        setDistrict(response['data'])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => { fetchDistrictList() }, []);
    return (
        <Wrapper>
            <Card className={classes.card}>
                <CardContent>
                    <Grid
                        spacing={10}
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Grid item xs={12} sm={6}>
                            <Typography variant="h4">
                                <ListIcon /> District Wise Report
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} className={classes.textAlignRight}>
                            <Link to={`/event/details/${id}`}>
                                <Button
                                    variant="contained"
                                    startIcon={<ArrowBackOutlinedIcon />}
                                    size="large"
                                >
                                    Back
                                </Button>
                            </Link>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            <Grid container spacing={1}>
                <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Id</TableCell>
                                <TableCell>District</TableCell>
                                <TableCell align="left">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {district.map((row) => (
                                <TableRow key={row.id}>
                                    <TableCell component="th" scope="row">
                                        {row.id}
                                    </TableCell>

                                    <TableCell component="th" scope="row">
                                        {row.district}
                                    </TableCell>

                                    <TableCell align="left" className={classes.actionButtons}>
                                        <Link to={`/event/dashboard/${id}/${row.id}`}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                style={{ marginRight: 4, marginBottom: "5px" }}
                                            >
                                                {<EditIcon />}
                                            </Button>
                                        </Link>
                                        <Button
                                            onClick={() => {
                                                downloadPDF(id, row.id)
                                            }}
                                            variant="contained"
                                            style={{
                                                marginRight: 4, marginBottom: "5px",
                                                backgroundColor: "#218838", color: "white"
                                            }}
                                        >
                                            {< VisibilityIcon />}
                                        </Button>

                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Wrapper>
    );
}
