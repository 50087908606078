import AccountCircleIcon from "@material-ui/icons/AccountCircle";

import VpnKeyIcon from "@material-ui/icons/VpnKey";

const logoutCandidate = () => {
  localStorage.clear();
  window.location.href = "/candidate/login";
};

const routesCandidate = [
  {
    name: "Profile",
    icon: AccountCircleIcon,
    path: "/candidate/profile",
    badge: 0,
  },

  {
    name: "Logout",
    icon: VpnKeyIcon,
    path: "",
    action: logoutCandidate,
    badge: 0,
  },
];

export default routesCandidate;
