import { domain } from "./domain";

const apiGeneral = {
  // Login
  login: `${domain}candidate/login`,
  userLogin: `${domain}user/login`,
  // Register
  register: `${domain}/candidate/register`,
  // Districts
  getDistricts: `${domain}/districts`,
  // create User
  createUser: `${domain}user/register`,
  // update user
  createUpdate: `${domain}user/update`,
  // delete user
  deleteUser: `${domain}user/delete`,
  // Candidate
  getCandidateProfile: `${domain}candidate/profile`,
  makePayment: `${domain}candidate/make_payment`,
  // userlist
  userlistGet: `${domain}user/list`,
  userDashboard: `${domain}user/dashboard`,
  // Candidate
  GetTmpCandidateList: `${domain}user/candidate/list/pending`,
  GetUnpaidCandidateList: `${domain}user/candidate/list/unpaid`,
  GetCandidateList: `${domain}user/candidate/list/verified`,
  candidateVerification: `${domain}user/candidate/verify`,
  candidateReject: `${domain}user/candidate/reject`,
  candidateCancel: `${domain}user/candidate/cancel`,
  editProfilePhoto: `${domain}candidate/profile/update/photo`,
  editDocuments: `${domain}candidate/profile/update/document`,
  editProfileDetails: `${domain}candidate/profile/update`,

  //events
  eventsGet: `${domain}user/events`,
  eventGet: `${domain}user/event`,
  deleteEvent: `${domain}user/event/delete/`,
  getCandidateEligibility: `${domain}user/event/candidate/eligible`,
  getCandidateList: `${domain}user/event/candidate/list`,
  getEventCandidateDelete: `${domain}user/event/candidate/delete`,
  addCoachesAndAuthorities: `${domain}user/event/coaches/add`,
  getAllCoaches: `${domain}user/event/coaches`,
  getCoachesAndAuthorities: `${domain}/user/event/coach/get`,
  //email send
  sendPassword: `${domain}user/send/password`,

  // Create Event
  createEvent: `${domain}/user/event/create`,

  //registration active deativate
  registrationPortalStatus: `${domain}/user/settings/get-registration-status`,
  registrationPortalStatusCandidate: `${domain}/settings/get-registration-status`,
  registrationPortalActive: `${domain}/user/settings/set-registration-status`,
};
export { apiGeneral };
