import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

import Button from "@material-ui/core/Button";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import { Box, Select, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import VisibilityIcon from "@material-ui/icons/Visibility";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles({
  table: {
    // minWidth: 650,
  },
  card: {
    marginBottom: 16,
  },
  actionButtons: {
    display: "flex",
    gap: 8, // Adjust the spacing between buttons
  },
  search: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid black",
    borderRadius: "5px",
  },
  dropDownField: {
    width: "200px",
    marginRight: "10px",
  },
  textAlignRight: {
    textAlign: "right",
  },
  formControl: {
    minWidth: 120,
  },
  select: {
    width: "200px",
  },
});

const Wrapper = ({ children }) => <div style={{ padding: 16 }}>{children}</div>;

const UserProfilePage = () => {
  const classes = useStyles();

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Wrapper>
      <Card className={classes.card}>
        <CardContent>
          <Grid
            spacing={10}
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item xs={12} sm={6}>
              <Typography variant="h4">
                <AccountBoxIcon /> User Profile
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} className={classes.textAlignRight}>
              <Link to="/users">
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<ArrowBackIcon />}
                  size="large"
                >
                  Back
                </Button>
              </Link>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Card className={classes.card}>
        <CardContent>
          <Grid spacing={2} container>
            <Grid item xs={12} sm={3}>
              <Card>
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <CardContent>
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlcnxlbnwwfHwwfHx8MA%3D%3D"
                        alt=""
                        height={"100px"}
                        width={"100px"}
                        style={{ borderRadius: "50px" }}
                      />
                      <Typography variant="h6">Swapnil Matgaonkar</Typography>
                    </Box>

                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography variant="subtitle1">Status:</Typography>
                      <Typography variant="h6" style={{ color: "green" }}>
                        Active
                      </Typography>
                    </Box>
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: "10px",
                        alignItems: "center",
                      }}
                    >
                      <Typography variant="subtitle1">Gender</Typography>
                      <Typography
                        variant="subtitle1"
                        style={{ fontWeight: "bold" }}
                      >
                        Male
                      </Typography>
                    </Box>
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: "10px",
                        alignItems: "center",
                      }}
                    >
                      <Typography variant="subtitle1">Age</Typography>
                      <Typography
                        variant="subtitle1"
                        style={{ fontWeight: "bold" }}
                      >
                        20
                      </Typography>
                    </Box>
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: "10px",
                        alignItems: "center",
                      }}
                    >
                      <Typography variant="subtitle1">Contact</Typography>
                      <Typography
                        variant="subtitle1"
                        style={{ fontWeight: "bold" }}
                      >
                        89849844
                      </Typography>
                    </Box>
                  </CardContent>
                </Box>
              </Card>
              <Card style={{ marginTop: "10px" }}>
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <CardContent>
                    <Typography variant="h6">Other Details</Typography>
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: "10px",
                        alignItems: "center",
                      }}
                    >
                      <Typography variant="subtitle1">Aadhar No</Typography>
                      <Typography
                        variant="subtitle1"
                        style={{ fontWeight: "bold" }}
                      >
                        8152-2158-1222
                      </Typography>
                    </Box>
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: "10px",
                        alignItems: "center",
                      }}
                    >
                      <Typography variant="subtitle1">Birth Proof</Typography>
                      <Typography
                        variant="subtitle1"
                        style={{ fontWeight: "bold" }}
                      >
                        Birth Certificate
                      </Typography>
                    </Box>
                    {/* <Box
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: "10px",
                        alignItems: "center",
                      }}
                    >
                      <Typography variant="subtitle1">CBC Count</Typography>
                      <Typography
                        variant="subtitle1"
                        style={{ fontWeight: "bold", color: "green" }}
                      >
                        Good
                      </Typography>
                    </Box> */}
                  </CardContent>
                </Box>
              </Card>
              <Card style={{ marginTop: "10px" }}>
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <CardContent>
                    <Typography variant="h6">Payment Details</Typography>
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: "10px",
                        alignItems: "center",
                      }}
                    >
                      <Typography variant="subtitle1">Payment ID</Typography>
                      <Typography
                        variant="subtitle1"
                        style={{ fontWeight: "bold" }}
                      >
                        8978745
                      </Typography>
                    </Box>
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: "10px",
                        alignItems: "center",
                      }}
                    >
                      <Typography variant="subtitle1">
                        Payment Status
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        style={{ fontWeight: "bold" }}
                      >
                        Credit
                      </Typography>
                    </Box>
                  </CardContent>
                </Box>
              </Card>

              {/* <Card style={{ marginTop: "10px" }}>
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <CardContent>
                    <Typography variant="h6">Address</Typography>
                    <Box>
                      <Typography variant="subtitle1">
                        Headache,Knee Pain,Last time he looked sick
                      </Typography>
                    </Box>
                  </CardContent>
                </Box>
              </Card> */}
            </Grid>
            <Grid item xs={12} sm={9}>
              <Box>
                <div className={classes.root}>
                  <AppBar
                    position="static"
                    style={{ backgroundColor: "#fff", color: "black" }}
                  >
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      aria-label="simple tabs example"
                    >
                      <Tab label="Details" {...a11yProps(0)} />
                      {/* <Tab label="Family" {...a11yProps(1)} />
                      <Tab label="Orders" {...a11yProps(2)} /> */}
                    </Tabs>
                  </AppBar>
                  {/* General Details */}
                  <TabPanel value={value} index={0}>
                    <Card>
                      <CardContent>
                        <div style={{ padding: "20px" }}>
                          <Typography variant="h6">Personal Details</Typography>
                          <Box
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "80px",
                            }}
                          >
                            <div>
                              <Typography
                                variant="subtitle1"
                                style={{ color: "gray" }}
                              >
                                Last Name
                              </Typography>
                              <Typography variant="subtitle1">
                                Swapnil
                              </Typography>
                            </div>
                            <div>
                              <Typography
                                variant="subtitle1"
                                style={{ color: "gray" }}
                              >
                                First Name
                              </Typography>
                              <Typography variant="subtitle1">
                                Jadhave Matgaonkar
                              </Typography>
                            </div>
                            <div>
                              <Typography
                                variant="subtitle1"
                                style={{ color: "gray" }}
                              >
                                Mother
                              </Typography>
                              <Typography variant="subtitle1">
                                Swapna
                              </Typography>
                            </div>
                            <div>
                              <Typography
                                variant="subtitle1"
                                style={{ color: "gray" }}
                              >
                                Birthdate
                              </Typography>
                              <Typography variant="subtitle1">
                                03/03/1996
                              </Typography>
                            </div>
                          </Box>
                        </div>
                        <div style={{ padding: "20px" }}>
                          <Box
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "80px",
                            }}
                          >
                            <div>
                              <Typography
                                variant="subtitle1"
                                style={{ color: "gray" }}
                              >
                                Address
                              </Typography>
                              <Typography variant="subtitle1">
                                Town hall, Aurangabad, MS
                              </Typography>
                            </div>
                            <div>
                              <Typography
                                variant="subtitle1"
                                style={{ color: "gray" }}
                              >
                                Phone Number
                              </Typography>
                              <Typography variant="subtitle1">
                                289-398-888
                              </Typography>
                            </div>
                            <div>
                              <Typography
                                variant="subtitle1"
                                style={{ color: "gray" }}
                              >
                                Email
                              </Typography>
                              <Typography variant="subtitle1">
                                swapnil@gmail.com
                              </Typography>
                            </div>
                          </Box>
                        </div>
                        {/* <div style={{ padding: "20px" }}>
                          <Typography variant="h6">Prescriptions</Typography>
                          <Box style={{ display: "flex", gap: "30px" }}>
                            <FormControl className={classes.formControl}>
                              <InputLabel htmlFor="age-native-simple">
                                Delivery Type
                              </InputLabel>
                              <Select
                                native
                                onChange={handleChange}
                                inputProps={{
                                  name: "age",
                                  id: "age-native-simple",
                                }}
                                className={classes.select}
                              >
                                <option aria-label="None" value="" />
                                <option value={10}>Ten</option>
                                <option value={20}>Twenty</option>
                                <option value={30}>Thirty</option>
                              </Select>
                            </FormControl>
                            <FormControl className={classes.formControl}>
                              <InputLabel htmlFor="age-native-simple">
                                Delivery Route
                              </InputLabel>
                              <Select
                                native
                                onChange={handleChange}
                                inputProps={{
                                  name: "age",
                                  id: "age-native-simple",
                                }}
                                className={classes.select}
                              >
                                <option aria-label="None" value="" />
                                <option value={10}>Ten</option>
                                <option value={20}>Twenty</option>
                                <option value={30}>Thirty</option>
                              </Select>
                            </FormControl>
                          </Box>
                          <Box style={{ display: "flex", gap: "30px" }}>
                            <FormControl className={classes.formControl}>
                              <InputLabel htmlFor="age-native-simple">
                                Price Group
                              </InputLabel>
                              <Select
                                native
                                onChange={handleChange}
                                inputProps={{
                                  name: "age",
                                  id: "age-native-simple",
                                }}
                                className={classes.select}
                              >
                                <option aria-label="None" value="" />
                                <option value={10}>Ten</option>
                                <option value={20}>Twenty</option>
                                <option value={30}>Thirty</option>
                              </Select>
                            </FormControl>
                            <FormControl className={classes.formControl}>
                              <InputLabel htmlFor="age-native-simple">
                                Drug Line
                              </InputLabel>
                              <Select
                                native
                                onChange={handleChange}
                                inputProps={{
                                  name: "age",
                                  id: "age-native-simple",
                                }}
                                className={classes.select}
                              >
                                <option aria-label="None" value="" />
                                <option value={10}>Ten</option>
                                <option value={20}>Twenty</option>
                                <option value={30}>Thirty</option>
                              </Select>
                            </FormControl>
                            <FormControl className={classes.formControl}>
                              <InputLabel htmlFor="age-native-simple">
                                Drug Line 2
                              </InputLabel>
                              <Select
                                native
                                onChange={handleChange}
                                inputProps={{
                                  name: "age",
                                  id: "age-native-simple",
                                }}
                                className={classes.select}
                              >
                                <option aria-label="None" value="" />
                                <option value={10}>Ten</option>
                                <option value={20}>Twenty</option>
                                <option value={30}>Thirty</option>
                              </Select>
                            </FormControl>
                          </Box>
                        </div> */}
                      </CardContent>
                    </Card>
                    <Card style={{ marginTop: "10px" }}>
                      <CardContent>
                        <Box style={{ display: "flex" }}>
                          <Button variant="contained" color="primary">
                            <VisibilityIcon style={{ marginRight: "5px" }} />{" "}
                            View Documents
                          </Button>
                        </Box>
                      </CardContent>
                    </Card>
                  </TabPanel>
                  {/* Family Details */}
                  <TabPanel value={value} index={1}>
                    {/* Family */}
                    <Card>
                      <CardContent>
                        <div style={{ padding: "20px" }}>
                          <Typography variant="h6">Family Details</Typography>
                          <Box
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "80px",
                            }}
                          >
                            <div>
                              <Typography
                                variant="subtitle1"
                                style={{ color: "gray" }}
                              >
                                Last Name
                              </Typography>
                              <Typography variant="subtitle1">Chris</Typography>
                            </div>
                            <div>
                              <Typography
                                variant="subtitle1"
                                style={{ color: "gray" }}
                              >
                                First Name
                              </Typography>
                              <Typography variant="subtitle1">
                                Marston
                              </Typography>
                            </div>
                            <div>
                              <Typography
                                variant="subtitle1"
                                style={{ color: "gray" }}
                              >
                                Relation
                              </Typography>
                              <Typography variant="subtitle1">
                                Father
                              </Typography>
                            </div>
                            <div>
                              <Typography
                                variant="subtitle1"
                                style={{ color: "gray" }}
                              >
                                Birthdate
                              </Typography>
                              <Typography variant="subtitle1">
                                03/03/1969
                              </Typography>
                            </div>
                          </Box>
                          <Box
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "80px",
                            }}
                          >
                            <div>
                              <Typography
                                variant="subtitle1"
                                style={{ color: "gray" }}
                              >
                                Last Name
                              </Typography>
                              <Typography variant="subtitle1">Nancy</Typography>
                            </div>
                            <div>
                              <Typography
                                variant="subtitle1"
                                style={{ color: "gray" }}
                              >
                                First Name
                              </Typography>
                              <Typography variant="subtitle1">
                                Marston
                              </Typography>
                            </div>
                            <div>
                              <Typography
                                variant="subtitle1"
                                style={{ color: "gray" }}
                              >
                                Relation
                              </Typography>
                              <Typography variant="subtitle1">
                                Mother
                              </Typography>
                            </div>
                            <div>
                              <Typography
                                variant="subtitle1"
                                style={{ color: "gray" }}
                              >
                                Birthdate
                              </Typography>
                              <Typography variant="subtitle1">
                                03/03/1970
                              </Typography>
                            </div>
                          </Box>
                        </div>
                        <div style={{ padding: "20px" }}>
                          <Box
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "80px",
                            }}
                          >
                            <div>
                              <Typography
                                variant="subtitle1"
                                style={{ color: "gray" }}
                              >
                                Address
                              </Typography>
                              <Typography variant="subtitle1">
                                83 Mile Drive, Los Angeles, CA
                              </Typography>
                            </div>
                            <div>
                              <Typography
                                variant="subtitle1"
                                style={{ color: "gray" }}
                              >
                                Phone Number
                              </Typography>
                              <Typography variant="subtitle1">
                                289-398-888
                              </Typography>
                            </div>
                            <div>
                              <Typography
                                variant="subtitle1"
                                style={{ color: "gray" }}
                              >
                                Email
                              </Typography>
                              <Typography variant="subtitle1">
                                chris@gmail.com
                              </Typography>
                            </div>
                          </Box>
                        </div>
                        <div style={{ padding: "20px" }}>
                          <Typography variant="h6">Prescriptions</Typography>
                          <Box style={{ display: "flex", gap: "30px" }}>
                            <FormControl className={classes.formControl}>
                              <InputLabel htmlFor="age-native-simple">
                                Delivery Type
                              </InputLabel>
                              <Select
                                native
                                onChange={handleChange}
                                inputProps={{
                                  name: "age",
                                  id: "age-native-simple",
                                }}
                                className={classes.select}
                              >
                                <option aria-label="None" value="" />
                                <option value={10}>Ten</option>
                                <option value={20}>Twenty</option>
                                <option value={30}>Thirty</option>
                              </Select>
                            </FormControl>
                            <FormControl className={classes.formControl}>
                              <InputLabel htmlFor="age-native-simple">
                                Delivery Route
                              </InputLabel>
                              <Select
                                native
                                onChange={handleChange}
                                inputProps={{
                                  name: "age",
                                  id: "age-native-simple",
                                }}
                                className={classes.select}
                              >
                                <option aria-label="None" value="" />
                                <option value={10}>Ten</option>
                                <option value={20}>Twenty</option>
                                <option value={30}>Thirty</option>
                              </Select>
                            </FormControl>
                          </Box>
                          <Box style={{ display: "flex", gap: "30px" }}>
                            <FormControl className={classes.formControl}>
                              <InputLabel htmlFor="age-native-simple">
                                Price Group
                              </InputLabel>
                              <Select
                                native
                                onChange={handleChange}
                                inputProps={{
                                  name: "age",
                                  id: "age-native-simple",
                                }}
                                className={classes.select}
                              >
                                <option aria-label="None" value="" />
                                <option value={10}>Ten</option>
                                <option value={20}>Twenty</option>
                                <option value={30}>Thirty</option>
                              </Select>
                            </FormControl>
                            <FormControl className={classes.formControl}>
                              <InputLabel htmlFor="age-native-simple">
                                Drug Line
                              </InputLabel>
                              <Select
                                native
                                onChange={handleChange}
                                inputProps={{
                                  name: "age",
                                  id: "age-native-simple",
                                }}
                                className={classes.select}
                              >
                                <option aria-label="None" value="" />
                                <option value={10}>Ten</option>
                                <option value={20}>Twenty</option>
                                <option value={30}>Thirty</option>
                              </Select>
                            </FormControl>
                            <FormControl className={classes.formControl}>
                              <InputLabel htmlFor="age-native-simple">
                                Drug Line 2
                              </InputLabel>
                              <Select
                                native
                                onChange={handleChange}
                                inputProps={{
                                  name: "age",
                                  id: "age-native-simple",
                                }}
                                className={classes.select}
                              >
                                <option aria-label="None" value="" />
                                <option value={10}>Ten</option>
                                <option value={20}>Twenty</option>
                                <option value={30}>Thirty</option>
                              </Select>
                            </FormControl>
                          </Box>
                        </div>
                      </CardContent>
                    </Card>
                  </TabPanel>
                  {/* Order Details */}
                  <TabPanel value={value} index={2}>
                    Orders
                  </TabPanel>
                </div>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Wrapper>
  );
};

export default UserProfilePage;
