import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Group from "@material-ui/icons/Group";
import { FormControl, TextField, Typography } from "@material-ui/core";
import clsx from "clsx";
import ArrowBackOutlinedIcon from "@material-ui/icons/ArrowBackOutlined";

import { Link, useParams } from "react-router-dom";
import { useLoading } from "../../Components/Layouts/LoadingProvider";
import { networkRequest } from "../../utils/networkRequest";
import { apiGeneral } from "../../utils/urls";
import { SaveAlt } from "@material-ui/icons";
import excelExport from "../../Extras/excel-export";

const useStyles = makeStyles({

  card: {
    marginBottom: 16,
    backgroundColor: "#3f51b5",
    color: "white",
  },
  actionButtons: {
    display: "flex",
    gap: 8, // Adjust the spacing between buttons
  },

  dropDownField: {
    width: "200px",
    marginRight: "10px",
  },
  textAlignRight: {
    textAlign: "right",
  },

});

export const sportList = [
  {
    value: "foil",
    label: "Foil",
  },
  {
    value: "epee",
    label: "Epee",
  },
  {
    value: "sabre",
    label: "Sabre",
  },

];
export const genders = [
  {
    value: "male",
    label: "Male",
  },
  {
    value: "Female",
    label: "female",
  },

];


const Wrapper = ({ children }) => <div style={{ padding: 16 }}>{children}</div>;

export default function SportWiseList() {
  const { id } = useParams();
  const classes = useStyles();
  const [sport, setSport] = React.useState('foil');
  const [gender, setGender] = React.useState('male');
  const { setLoading } = useLoading();
  const [candidateList, setCandidateList] = useState([]);
  const isLoading = (bool) => {
    setLoading(bool);
  }

  const handleSubmit = () => {
    networkRequest(
      apiGeneral.getCandidateList,
      isLoading,
      (response) => {
        setCandidateList(response["data"]['candidate']);
      }, 'get', { event_id: id, sport: sport, gender: gender }
    );
  };


  const handleChange = (event) => {
    setSport(event.target.value);
  };
  const genderHandle = (event) => {
    setGender(event.target.value);
  };

  const transformData = (data) => {
    return data.map((item, index) => ({
      "Serial No.": index + 1,
      "MF ID": item.mf_id,
      "Name": item.name,
      "Contact": item.contact,
      "Aadhaar": item.aadhaar,
      "Date of Birth": item.dob,
      "Gender": item.gender,
      "District": item.district,
      "School": item.school,
      "Class": item.class,
      "FAI": item.fai
    }));
  };
  const handleExport = () => {
    const transformed = transformData(candidateList);
    excelExport(transformed, `candidate_list_${gender}_${sport}`);
  }

  return (
    <Wrapper>
      <Card className={classes.card}>
        <CardContent>
          <Grid
            spacing={0}
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item xs={12} sm={3}>
              <Typography variant="h5">
                <Group /> Sport Wise List
              </Typography>
            </Grid>
            <Grid item={12} sm={9}>
              <div style={{ padding: "10px", backgroundColor: "white", borderRadius: "5px" }}>
                <FormControl className={clsx(classes.margin, classes.textField)}>
                  <TextField
                    id="outlined-select-DivisionClasses-native"
                    select
                    label="SPORT"
                    value={sport}
                    onChange={handleChange}
                    SelectProps={{
                      native: true,
                    }}
                    variant="outlined"
                    className={classes.dropDownField}
                    size="small"
                  >
                    {sportList.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </TextField>
                </FormControl>
                <FormControl className={clsx(classes.margin)}>
                  <TextField
                    id="outlined-select-DivisionClasses-native"
                    select
                    label="GENDER"
                    value={gender}
                    onChange={genderHandle}
                    SelectProps={{
                      native: true,
                    }}
                    variant="outlined"
                    className={classes.dropDownField}
                    size="small"
                  >
                    {genders.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </TextField>
                </FormControl>
                <Button
                  onClick={() => { handleSubmit() }}
                  variant="contained"
                  color="Primary"
                  size="small"
                  // className={clsx(classes.margin, classes.button)}
                  style={{ marginRight: "10px" }}
                >
                  Submit
                </Button>
                <Button
                  onClick={handleExport}
                  variant="contained"
                  startIcon={<SaveAlt />}
                  size="small"
                  style={{ marginRight: "10px" }}
                >
                  Export
                </Button>
                <Link to={`/event/details/${id}`}>
                  <Button
                    variant="contained"
                    startIcon={<ArrowBackOutlinedIcon />}
                    size="small"
                  >
                    Back
                  </Button>
                </Link>

              </div>


            </Grid>

          </Grid>
        </CardContent>
      </Card>
      <Grid container spacing={1}>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Sr. No</TableCell>
                <TableCell>NAME</TableCell>
                <TableCell>DOB</TableCell>
                <TableCell>GENDER</TableCell>
                <TableCell>DISTRICT</TableCell>
                <TableCell>SCHOOL/COLLEGE</TableCell>
                <TableCell>CLASS</TableCell>
                <TableCell>Player ID</TableCell>
                <TableCell>FAI ID</TableCell>
                {/* <TableCell align="left">Actions</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {candidateList.map((row, index) => (
                <TableRow key={row.id}>
                  <TableCell component="th" scope="row">
                    {index + 1}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.dob}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.gender}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.district}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.school}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.class}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    MF{row.mf_id}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.fai}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>    </Wrapper>
  );
}
