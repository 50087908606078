import React, { useEffect, useState } from "react";

import Grid from "@material-ui/core/Grid";

import Wrapper from "../../Components/Wrapper";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles } from "@material-ui/core/styles";
import { CardActionArea, Typography } from "@material-ui/core";
import PeopleIcon from "@material-ui/icons/People"; // Importing the People icon from Material-UI icons
import EventNoteIcon from "@material-ui/icons/EventNote";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import AccountCircleRoundedIcon from "@material-ui/icons/AccountCircleRounded";
import { apiGeneral } from "../../utils/urls";
import { networkRequest } from "../../utils/networkRequest";
import { useLoading } from "../../Components/Layouts/LoadingProvider";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles({
  table: {
    // minWidth: 650,
  },
  card: {
    marginBottom: 16,
    backgroundColor: "#3f51b5",
    color: "black",
  },
  actionButtons: {
    display: "flex",
    gap: 8, // Adjust the spacing between buttons
  },
  search: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid black",
    borderRadius: "5px",
  },
  dropDownField: {
    width: "200px",
    marginRight: "10px",
  },
  textAlignRight: {
    textAlign: "right",
  },
  root: {
    maxWidth: 345,
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)", // Adding a background shadow
    borderRadius: 10,
    overflow: "hidden",
  },
  media: {
    height: 300,
    objectFit: "cover",
  },
  iconContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%", // Ensure the container takes the full height of the CardActionArea
    backgroundColor: "#3f51b5",
    padding: "10px",
  },
  cardContent: {
    backgroundColor: "#f5f5f5", // Background color for the card content
    borderTop: "1px solid #ddd", // Adding a border at the top of the card content
    borderBottomLeftRadius: 16,
    borderBottomRightRadius: 16,
  },
  numberContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%", // Ensure the container takes the full height of the CardActionArea
  },
});

const Dashboard = () => {
  const classes = useStyles();
  const { loading, setLoading } = useLoading();
  const navigate = useNavigate();

  const isLoading = (bool) => {
    setLoading(bool);
  }

  const [dashboardData, setDashboard] = useState({
    "registeredCandidate": 0,
    "users": 0,
    "pendingCandidate": 0,
    "allEvents": 0
  });

  const loadDashboardData = () => {
    networkRequest(apiGeneral.userDashboard, isLoading, handleResponse);
  }
  const handleResponse = (data) => {
    setDashboard(data['data']);
  }
  useEffect(() => { loadDashboardData() }, []);
  return (
    <Wrapper>
      <Grid container spacing={2}>
        <Grid item xs={6} sm={6} md={3}>
          <Card className={classes.root} onClick={(e) => {
            navigate('/candidate/list')
          }}>
            <CardActionArea>
              <div className={classes.iconContainer}>
                <PeopleIcon fontSize="large" />
              </div>
              <CardContent className={classes.cardContent}>
                <Typography
                  gutterBottom
                  variant="h5"
                  component="h2"
                  align="center"
                >
                  <div className={classes.numberContainer}>
                    <Typography variant="h6">{dashboardData['registeredCandidate']}</Typography>
                  </div>
                  All Candidates
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
        <Grid item xs={6} sm={6} md={3}>
          <Card className={classes.root} onClick={(e) => {
            navigate('/candidate/unverified')
          }}>
            <CardActionArea>
              <div className={classes.iconContainer}>
                <AssignmentIndIcon fontSize="large" />
              </div>
              <CardContent className={classes.cardContent}>
                <Typography
                  gutterBottom
                  variant="h5"
                  component="h2"
                  align="center"
                >
                  <div className={classes.numberContainer}>
                    <Typography variant="h6">{dashboardData['pendingCandidate']}</Typography>
                  </div>
                  Pending Candidates
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
        <Grid item xs={6} sm={6} md={3}>
          <Card className={classes.root} onClick={(e) => {
            navigate('/events')
          }}>
            <CardActionArea>
              <div className={classes.iconContainer}>
                <EventNoteIcon fontSize="large" />
              </div>
              <CardContent className={classes.cardContent}>
                <Typography
                  gutterBottom
                  variant="h5"
                  component="h2"
                  align="center"
                >
                  <div className={classes.numberContainer}>
                    <Typography variant="h6">{dashboardData.allEvents}</Typography>
                  </div>
                  All Events
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
        <Grid item xs={6} sm={6} md={3}>
          <Card className={classes.root}>
            <CardActionArea>
              <div className={classes.iconContainer}>
                <AccountCircleRoundedIcon fontSize="large" />
              </div>
              <CardContent className={classes.cardContent}>
                <Typography
                  gutterBottom
                  variant="h5"
                  component="h2"
                  align="center"
                >
                  <div className={classes.numberContainer}>
                    <Typography variant="h6">{dashboardData['users']}</Typography>
                  </div>
                  Total Users
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      </Grid>
    </Wrapper>
  );
};

export default Dashboard;
