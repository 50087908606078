import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import EventNoteIcon from "@material-ui/icons/EventNote";
import {
  FormControl,
  FormHelperText,
  TextField,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Cancel, Save } from "@material-ui/icons";
import { useLoading } from "../../Components/Layouts/LoadingProvider";
import { networkRequest } from "../../utils/networkRequest";
import { apiGeneral } from "../../utils/urls";
import Swal from "sweetalert2";

const useStyles = makeStyles((theme) => ({

  card: {
    marginBottom: 16,
    display: "flex",
    gap: 8,
  },
  search: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid black",
    borderRadius: "5px",
  },
  margin: {
    margin: theme.spacing(2),
  },
  textAlignRight: {
    textAlign: "right",
  },
  bgPrimary: {
    width: "100%",
    backgroundColor: "#3f51b5",
    color: "white",
  },
  padding: {
    padding: theme.spacing(2),
  },
  textField: {
    width: "60ch",
  },

  textField20ch: {
    width: "20ch",
  },
  truncateText: {
    maxWidth: "200px", // Adjust as needed
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  errorhelperText: {
    color: "#FF6868",
    fontSize: "1rem",
  },
}));

const Wrapper = ({ children }) => <div style={{ padding: 16 }}>{children}</div>;

export default function AddEvents() {
  const classes = useStyles();
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    id: "",
    name: "",
    venue: "",
    start_date: "",
    end_date: "",
    registration_start_date: "",
    registration_end_date: "",
    participation_age_from: "",
    participation_age_to: "",
  });

  // eslint-disable-next-line no-unused-vars
  const { loading, setLoading } = useLoading();
  const location = useLocation();

  const isLoading = (bool) => {
    setLoading(bool);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const validateForm = () => {
    const errorsCopy = {}; // Initialize a new errors object

    // Validate Event Name
    if (formData.name.trim() === "") {
      errorsCopy.name = "Event Name is Required";
    }

    // Validate venue
    if (formData.venue === "") {
      errorsCopy.venue = "Venue is Required ";
    }
    // Validate Event Date
    if (formData.start_date === "") {
      errorsCopy.start_date = "Event Start Date is Required ";
    }
    // validations for End Date should not be greater than star date
    if (formData.end_date === "") {
      errorsCopy.end_date = "Event End Date is Required ";
    }
    
    if ((formData.end_date) < (formData.start_date)) {
      errorsCopy.end_date = "Event End Date should not be earlier than Start Date";
    }

    // Validate Registration Date 
    if (formData.registration_start_date === "") {
      errorsCopy.registration_start_date = "Registration Start Date  is Required ";
    }
    if (formData.registration_end_date === "") {
      errorsCopy.registration_end_date = "Registration End Date is Required ";
    }
    if ((formData.registration_end_date) < (formData.registration_start_date)) {
      errorsCopy.registration_end_date = "Registration End Date should not be earlier than  Start Date";
    }


    // Validate Participation Age From date
    if (formData.participation_age_from === "") {
      errorsCopy.participation_age_from =
        "Participation Age From Date is Required ";
    }
    // Validate Participation Age To date
    if (formData.participation_age_to === "") {
      errorsCopy.participation_age_to = "Participation Age To Date is Required ";
    }

    if ((formData.participation_age_to) < (formData.participation_age_from)) {
      errorsCopy.participation_age_to = "Registration End Date should not be earlier than  Start Date";
    }

    setErrors(errorsCopy);
  };

  useEffect(
    (e) => {
      validateForm();
      isLoading();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formData]
  );

  useEffect(() => {
    console.log(location.state)
    if (location.state !== null) {
      setFormData(location.state)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const navigate = useNavigate();

  const handleCreateEvent = (e) => {
    e.preventDefault();

    if (Object.keys(errors).length !== 0) {
      return;
    }

    const formDataToSend = new FormData();

    Object.entries(formData).forEach(([k, v]) => {
      formDataToSend.append(k, v);
    });

    networkRequest(
      apiGeneral.createEvent,
      isLoading,
      handleCreateEventSuccess,
      "post",
      formDataToSend
    );
  };
  const handleCreateEventSuccess = (response) => {
    Swal.fire({
      icon: "success",
      title: "SUCCESS",
      text: response["message"],
    });
    navigate("/events");
  };

  return (
    <Wrapper>
      <Card className={classes.card}>
        <Grid sm={12}>
          <Typography
            className={clsx(classes.padding, classes.bgPrimary)}
            variant="h4"
          >
            <EventNoteIcon />
            Events
          </Typography>
          <Card>
            <FormControl className={clsx(classes.margin, classes.textField)}>
              <TextField
                name="name"
                variant="outlined"
                id="venue"
                type="text"
                label="Event Name"
                autoFocus
                value={formData.name}
                onChange={handleInputChange}
              />
              {errors.name && (
                <FormHelperText className={classes.errorhelperText}>
                  {errors.name}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl className={clsx(classes.margin, classes.textField)}>
              <TextField
                name="venue"
                variant="outlined"
                id="venue"
                label="Venue Name"
                autoFocus
                value={formData.venue}
                onChange={handleInputChange}
              />
              {errors.venue && (
                <FormHelperText className={classes.errorhelperText}>
                  {errors.venue}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl className={clsx(classes.margin, classes.textField)}>
              <TextField
                id="date"
                label="EVENT DATE"
                type="date"
                variant="outlined"
                name="start_date"
                value={formData.start_date}
                onChange={handleInputChange}
                defaultValue="dd-mm-year"
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              {errors.start_date && (
                <FormHelperText className={classes.errorhelperText}>
                  {errors.start_date}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl className={clsx(classes.margin, classes.textField)}>
              <TextField
                id="date"
                label="EVENT END DATE"
                type="date"
                variant="outlined"
                name="end_date"
                value={formData.end_date}
                onChange={handleInputChange}
                defaultValue="dd-mm-year"
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              {errors.end_date && (
                <FormHelperText className={classes.errorhelperText}>
                  {errors.end_date}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl className={clsx(classes.margin, classes.textField)}>
              <TextField
                id="date"
                variant="outlined"
                label="REGISTRATION START DATE"
                type="date"
                name="registration_start_date"
                value={formData.registration_start_date}
                onChange={handleInputChange}
                defaultValue="dd-mm-year"
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              {errors.registration_start_date && (
                <FormHelperText className={classes.errorhelperText}>
                  {errors.registration_start_date}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl className={clsx(classes.margin, classes.textField)}>
              <TextField
                id="date"
                variant="outlined"
                label="REGISTRATION END DATE"
                type="date"
                name="registration_end_date"
                value={formData.registration_end_date}
                onChange={handleInputChange}
                defaultValue="dd-mm-year"
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              {errors.registration_end_date && (
                <FormHelperText className={classes.errorhelperText}>
                  {errors.registration_end_date}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl className={clsx(classes.margin, classes.textField)}>
              <TextField
                id="date"
                variant="outlined"
                label="PARTICIPATION AGE FROM "
                type="date"
                name="participation_age_from"
                value={formData.participation_age_from}
                onChange={handleInputChange}
                defaultValue="dd-mm-year"
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              {errors.participation_age_from && (
                <FormHelperText className={classes.errorhelperText}>
                  {errors.participation_age_from}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl className={clsx(classes.margin, classes.textField)}>
              <TextField
                id="date"
                variant="outlined"
                label="PARTICIPATION AGE TO"
                type="date"
                name="participation_age_to"
                value={formData.participation_age_to}
                onChange={handleInputChange}
                defaultValue="dd-mm-year"
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              {errors.participation_age_to && (
                <FormHelperText className={classes.errorhelperText}>
                  {errors.participation_age_to}
                </FormHelperText>
              )}
            </FormControl>
            <Card>
              <Button
                variant="contained"
                color="Primary"
                size="large"
                className={clsx(classes.margin, classes.button)}
                startIcon={<Save />}
                onClick={handleCreateEvent}
              >
                Save
              </Button>
              <Link to={"/events"}>
                <Button
                  variant="contained"
                  color="Default"
                  size="large"
                  className={clsx(classes.margin, classes.button)}
                  startIcon={<Cancel />}
                >
                  Cancel
                </Button>
              </Link>
            </Card>
          </Card>
        </Grid>
      </Card>
    </Wrapper>
  );
}
