// Capitalize
export function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function getFileExtension(url) {
  try {
    const pathname = new URL(url).pathname;
    const extension = pathname.split('.').pop();
    return extension.includes('/') ? 'png' : extension;
  } catch (e) {
    // If URL is not valid, return 'png'
    return 'png';
  }
}


// Format price
export function formatPrice(number) {
  const fnumber = parseFloat(number);
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(fnumber);
}
