import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Typography } from "@material-ui/core";
import ArrowBackOutlinedIcon from "@material-ui/icons/ArrowBackOutlined";
import { Link, useParams } from "react-router-dom";
import PeopleIcon from "@material-ui/icons/People";
import { networkRequest } from "../../../utils/networkRequest";
import { apiGeneral } from "../../../utils/urls";
import { useLoading } from "../../../Components/Layouts/LoadingProvider";
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import { SaveAlt } from "@material-ui/icons";
import ExcelExport from "../../../Extras/excel-export";
import excelExport from "../../../Extras/excel-export";

const useStyles = makeStyles({
  table: {
    // minWidth: 650,
  },
  card: {
    marginBottom: 16,
    backgroundColor: "#3f51b5",
    color: "white",
  },
  // head:{
  //   backgroundColor:"#3f51b5"
  // },
  actionButtons: {
    display: "flex",
    gap: 8, // Adjust the spacing between buttons
  },
  search: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid black",
    borderRadius: "5px",
  },
  dropDownField: {
    width: "200px",
    marginRight: "10px",
  },
  textAlignRight: {
    textAlign: "right",
  },
});

const Wrapper = ({ children }) => <div style={{ padding: 16 }}>{children}</div>;

export default function EventCandidates() {
  const classes = useStyles();
  const { setLoading } = useLoading();
  const { id } = useParams();
  const [candidateList, setCandidateList] = useState([]);
  const isLoading = (bool) => {
    setLoading(bool);
  }

  const getCandidateList = () => {
    networkRequest(
      apiGeneral.getCandidateList,
      isLoading,
      (response) => {
        console.log(response["data"])
        setCandidateList(response["data"]['candidate']);
      }, 'get', { event_id: id }
    );
  };

  useEffect(() => {
    getCandidateList();
  }, [])

  const renderIcon = (value) => {
    return value === "0" ? <CloseIcon /> : <CheckIcon />;
  };

  const transformData = (data) => {
    return data.map((item, index) => ({
      "Serial No.": index + 1,
      "MF ID": item.mf_id,
      "Name": item.name,
      "Email": item.email,
      "Mother": item.mother,
      "Address": item.address,
      "District": item.district,
      "Foil": item.foil === "1" ? "Y" : "N",
      "Epee": item.epee === "1" ? "Y" : "N",
      "Sabre": item.sabre === "1" ? "Y" : "N",
      "School": item.school,
      "Class": item.class,
      "Date of Birth": item.dob,
      "Gender": item.gender,
      "Contact": item.contact,
      "Aadhaar": item.aadhaar,
      "FAI": item.fai
    }));
  };

  const handleExport = () => {
    const transformed = transformData(candidateList);
    excelExport(transformed, `candidate_list_${id}`);
  }



  return (
    <Wrapper>
      <Card className={classes.card}>
        <CardContent>
          <Grid
            spacing={10}
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item xs={12} sm={6}>
              <Typography variant="h4">
                <PeopleIcon /> All Candidate
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} className={classes.textAlignRight}>
              <Button
                onClick={handleExport}
                variant="contained"
                startIcon={<SaveAlt />}
                size="large"
              >
                Excel Export
              </Button>
              |
              <Link to={`/event/details/${id}`}>
                <Button
                  variant="contained"
                  startIcon={<ArrowBackOutlinedIcon />}
                  size="large"
                >
                  Back
                </Button>
              </Link>

            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Grid container spacing={1}>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Sr. No</TableCell>
                <TableCell>NAME</TableCell>
                <TableCell>DOB</TableCell>
                <TableCell>GENDER</TableCell>
                <TableCell>DISTRICT</TableCell>
                <TableCell>SCHOOL/COLLEGE</TableCell>
                <TableCell>CLASS</TableCell>
                <TableCell>FAI ID</TableCell>
                <TableCell>PLAYER ID</TableCell>
                <TableCell>FOIL</TableCell>
                <TableCell>EPEE</TableCell>
                <TableCell>SABRE</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {candidateList.map((row, index) => (
                <TableRow key={row.id}>
                  <TableCell component="th" scope="row">
                    {index + 1}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.dob}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.gender}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.district}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.school}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.class}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.fai}
                  </TableCell>
                  <TableCell align="left">MF{row.mf_id}</TableCell>
                  <TableCell component="th" scope="row">
                    {renderIcon(row.foil)}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {renderIcon(row.epee)}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {renderIcon(row.sabre)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Wrapper>
  );
}
